import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContribuyenteComponent } from './contribuyente/contribuyente.component';
import { EscriturasComponent } from './escrituras/escrituras.component';
import { FirmantesComponent } from './firmantes/firmantes.component';
import { DomiciliosComponent } from './domicilios/domicilios.component';
import { RouterModule } from '@angular/router';
import { MantContComponent } from './mantcont.component';
import { ActaCComponent } from './actaconstitutiva/actac.component';
import { AfiliacionComponent } from './afiliaciion/afiliacion.component';
import { CapAfiliacionComponent } from './capafiliacion/capafiliacion.component';
import { CaratulaComponent } from './caratula/caratula.component'
import { MantContMainComponent } from './mantcontmain.component'; 
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TableModule,
        FormsModule, 
        ReactiveFormsModule


    ],
    declarations: [
        ContribuyenteComponent,
        MantContComponent,
        MantContMainComponent,
        EscriturasComponent,
        FirmantesComponent,
        DomiciliosComponent,
        ActaCComponent,
        AfiliacionComponent,
        CapAfiliacionComponent,
        CaratulaComponent
    ],
    exports: [
        ContribuyenteComponent,
        MantContComponent,
        MantContMainComponent,
        EscriturasComponent,
        FirmantesComponent,
        DomiciliosComponent,
        ActaCComponent,
        AfiliacionComponent,
        CapAfiliacionComponent,
        CaratulaComponent
    ]
})
export class MantContModule { }
