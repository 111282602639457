import { Component, OnInit, EventEmitter, OnDestroy, AfterViewInit, Output } from '@angular/core';
import { ContribuyentesService, OptionsService, MantenimientoContribuyentesService, MantContEmit } from '../../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-firmantes',
  templateUrl: './firmantes.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class FirmantesComponent implements OnInit, OnDestroy  {
  //para que se subscriba el padre y emita cambios
  @Output() cambioDetectado = new EventEmitter<any>();
  message: string;
  firmantes: any[] = [];
  firmante: any[] = [];
  idc: any;
  escrituras: any[] = [];
  parentesco: any[] = [];
  firmantesfisicas: any[] = [];
  contribuyentes: any[];
  anfb = false;
  private subscription: Subscription;
  constructor(
               public _contribuyentesService: ContribuyentesService,
               public _optionsservice: OptionsService,
               public _mantenimientocontservice: MantenimientoContribuyentesService,
               public router: Router,
               public http: HttpClient,
               private eventService: MantContEmit ) {
               
                }

  ngOnInit() {
    this.subscription = this.eventService.firmantesEmitter.subscribe((data: {idc: number}) => {
      //console.log('data recibida firmantes: ', data);
      this.handleFirmanteEvent(data);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    //console.log('DESTRUYO FIRMANTES')
  }

  handleFirmanteEvent(data: {idc: any}) {
    this.anfb = false;
    this.firmante = [];
    this.firmantes = [];
    this.idc = data.idc;     
    this._mantenimientocontservice.getEscrituras( this.idc ).subscribe( resp => {this.escrituras = resp; } );
    this._contribuyentesService.getParentezco().subscribe( resp => this.parentesco = resp );
    this._contribuyentesService.getFirmantes().subscribe( resp => {this.firmantesfisicas = resp;});
    this._mantenimientocontservice.getFirmantes(this.idc).subscribe( resp => {this.firmantes = resp; 

} );
  }

anf() {
  this.anfb = !this.anfb;
}

agregaFirmante() {
  swal2.showLoading();
 // const escritura: any = document.getElementById('escriturafirmantep');
 // const valorescritura = escritura.options[escritura.selectedIndex].value;
  const parentesco: any = document.getElementById('fpartnershipp');
  const valorparentesco = parentesco.options[parentesco.selectedIndex].value;
  const contribuyente: any = document.getElementById('firmantep');
  const valorcontribuyente = contribuyente.options[contribuyente.selectedIndex].value;
  const params = {
  token: '',
  secret_key: '',
  contributor_signatory_id: valorcontribuyente,
  property_document_id: '',
  partnership: valorparentesco
};
  this._contribuyentesService.creaFirmantexContribuyente(this.idc, params).subscribe( resp => {
    swal2.close();
    swal2.fire({
      title: 'Los datos se guardaron',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.handleFirmanteEvent({idc: this.idc});
        this.cambioDetectado.emit();
      }
    } );
    }, (err) => {
    swal2.close();
    console.log(err);
    swal2.fire({
      title: 'Ocurrio un error',
      text: '',
      icon: 'error',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.handleFirmanteEvent({idc: this.idc});
        this.cambioDetectado.emit();
      }
    } );
    });
}

getFirmante() {
  const firmante: any = document.getElementById('firmantes');
  const valorfirmante = firmante.options[firmante.selectedIndex].value;
  if (valorfirmante !== 'novalor') {
    const person = this.firmantes.find(object => {
      return object.id == valorfirmante;
    });
    this.firmante[0] = person;
} else {
  this.firmante = [];
}
}

modificaFirmante(item) {
  swal2.showLoading();
  const params = {
    token: '',
    secret_key: '',
    contributor_signatory_id: item.contributor_signatory_id,
    property_document_id: '',//item.property_document_id,
    partnership: item.partnership
  };

  if (item.property_document_id === null) {
    delete params.property_document_id;
  }

  this._mantenimientocontservice.actualizaFirmante( item.contributor_id, item.id, params ).subscribe( resp => {
    swal2.close();
    swal2.fire({
      title: 'Los datos se guardaron',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {

      if ( res.value ) {
        this.handleFirmanteEvent({idc: this.idc});
      }

    } );

}, (err) => {
swal2.close();
console.log(err);
swal2.fire({
  title: 'Ocurrio un error',
  text: '',
  icon: 'error',
  showConfirmButton: true,
  showCancelButton: false,
  allowOutsideClick: false
}). then ( res => {
  if ( res.value ) {
    window.location.reload();
  }
} );
});
}

borraFirmante(item) {
swal2.fire({
  title: 'Desea elminiar el firmante seleccionado',
  text: '?',
  icon: 'question',
  showConfirmButton: true,
  showCancelButton: true,
  allowOutsideClick: false
}). then ( resp => {
  if ( resp.value) {

    this._mantenimientocontservice.borraFirmante( item.contributor_id, item.id ).subscribe( () => {

      swal2.fire({
        title: 'El firmante se elimino',
        text: 'Con exito',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          this.handleFirmanteEvent({idc: this.idc});
          this.cambioDetectado.emit();
        }
      } );
}, (err) => {
  console.log(err);
  swal2.fire({
    title: 'Ocurrio un error',
    text: '',
    icon: 'error',
    showConfirmButton: true,
    showCancelButton: false,
    allowOutsideClick: false
  }). then ( res => {
    if ( res.value ) {
      this.handleFirmanteEvent({idc: this.idc});
    }
  } );
 });

  }
});
}



}