import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  LoginGuardGuard,
  regfactcopachisaGuard,
 // LoginRGuardGuard,
  AltaFacturasGuard,
  AltaFacturasddGuard,
  AuthRedirectGuard,
  RolesGuard,
  UserOptionsGuard,
  VerUsuariosGuard,
  PrivilegiosUsuariosGuard,
  ParametrosGuard,
  OptionsGuard,
  UsuariosCadenasGuard,
  ListasGuard,
  RolesOptionsGuard,
  AltaContribuyentesGuard,
  StpAccountsGuard,
  MantenimientoContribuyentesGuard,
  MantContGuard,
  AltaSolicitudesGuard,
  EstatusSolicitudesGuard,
  ReporteFacturasGuard,
  ReporteDiarioGuard,
  ReporteBancosGuard,
  ReportePagosCompanyGuard,
  ReporteFacturasDetallesGuard,
  ReporteSolicitudesGuard,
  ReporteSolicitudesDetallesGuard,
  ReportecfdisGuard,
  PaymentCfdiGuard,
  ReporteDirectorGuard,
  ReporteSolicitudesFondeoGuard,
  ProyectosProveedoresGuard,
  PagosProveedorGuard,
  PagosCadenaGuard,
  TarifasGuard,
  AltacypGuard,
  UsuariosProveedoresGuard,
  ContribuyentesFundersGuard,
  SolicituddeFondeoGuard,
  PagosafinancieraGuard,
  PagosafondeadorGuard,
  CuentasContablesGuard,
  GeneraPolizaGuard,
  ConsultaPolizaGuard,
  CobranzaDirectaGuard,
  SoporteGuard,

  SettingsService,
  SidebarService,
  SharedService,
  UsuarioService,
  StpService,
  PrivilegiosUsuariosService,
  RolesService,
  ParametrosGeneralesService,
  OptionsService,
  UserOptionsService,
  ListasService,
  RolesOptionsService,
  ContribuyentesService,
  AltaSolicitudesService,
  ReportesService,
  PagosService,
  DashboardService,
  TarifasService,
  FacturasService,
  MantenimientoContribuyentesService,
  FundersService,
  MiFielService,
  GuardService,
  ReportesPDFService,
  ContabilidadService,
  MantContEmit,
  NotionService
 } from './service.index';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    LoginGuardGuard,
    regfactcopachisaGuard,
   // LoginRGuardGuard,
    AltaFacturasGuard,
    AltaFacturasddGuard,
    AuthRedirectGuard,
    RolesGuard,
    UserOptionsGuard,
    VerUsuariosGuard,
    PrivilegiosUsuariosGuard,
    ParametrosGuard,
    OptionsGuard,
    UsuariosCadenasGuard,
    ListasGuard,
    RolesOptionsGuard,
    AltaContribuyentesGuard,
    StpAccountsGuard,
    MantenimientoContribuyentesGuard,
    MantContGuard,
    AltaSolicitudesGuard,
    EstatusSolicitudesGuard,
    ReporteFacturasGuard,
    ReporteDiarioGuard,
    ReporteBancosGuard,
    ReportePagosCompanyGuard,
    ReporteFacturasDetallesGuard,
    ReporteSolicitudesGuard,
    ReporteSolicitudesDetallesGuard,
    ReportecfdisGuard,
    PaymentCfdiGuard,
    ReporteDirectorGuard,
    ReporteSolicitudesFondeoGuard,
    ProyectosProveedoresGuard,
    PagosProveedorGuard,
    PagosCadenaGuard,
    TarifasGuard,
    AltacypGuard,
    UsuariosProveedoresGuard,
    ContribuyentesFundersGuard,
    SolicituddeFondeoGuard,
    PagosafinancieraGuard,
    PagosafondeadorGuard,
    CuentasContablesGuard,
    GeneraPolizaGuard,
    ConsultaPolizaGuard,
    CobranzaDirectaGuard,
    SoporteGuard,

    SettingsService,
    SidebarService,
    SharedService,
    UsuarioService,
    StpService,
    PrivilegiosUsuariosService,
    RolesService,
    ParametrosGeneralesService,
    OptionsService,
    UserOptionsService,
    ListasService,
    RolesOptionsService,
    ContribuyentesService,
    AltaSolicitudesService,
    ReportesService,
    PagosService,
    DashboardService,
    TarifasService,
    FacturasService,
    MantenimientoContribuyentesService,
    FundersService,
    MiFielService,
    GuardService,
    ReportesPDFService,
    ContabilidadService,
    MantContEmit,
    NotionService
  ],
  declarations: []
})
export class ServiceModule { }
