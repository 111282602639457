import { Component, OnInit, Input, EventEmitter, OnDestroy, AfterViewInit, ViewChild, Output  } from '@angular/core';
import { ContribuyentesService, OptionsService, MantenimientoContribuyentesService, UsuarioService } from '../../../services/service.index';
import { AwsService } from 'src/app/services/awsser/aws.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import { forkJoin, of, Subscription } from 'rxjs';
import { MantContEmit } from '../../../services/mantcontemitter/mantcontemiiter.service';
import { DomiciliosComponent } from '../domicilios/domicilios.component';
import { FirmantesComponent } from '../firmantes/firmantes.component';
import { ActaCComponent } from '../actaconstitutiva/actac.component';
import { CaratulaComponent } from '../caratula/caratula.component';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-capafiliacion',
  templateUrl: './capafiliacion.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class CapAfiliacionComponent implements OnInit, OnDestroy   {
  @Input() eventEmitter: EventEmitter<string>;
  @ViewChild(DomiciliosComponent, {static: false}) domiciliosComponent: DomiciliosComponent;
  @ViewChild(FirmantesComponent, {static: false}) firmantesComponent: FirmantesComponent;
  @ViewChild(ActaCComponent, {static: false}) actasComponent: ActaCComponent;
  @ViewChild(CaratulaComponent, {static: false}) caratulaComponent: CaratulaComponent;
  @Output() childEvent = new EventEmitter();
  archivoNombresSubidos = [];
  message: string;
  animationClassRo1 = '';
  animationClassRo2 = '';
  animationClassRo3 = '';
  animationClassRo4 = '';
  idc: string;
  ro1 = false;
  ro2 = false;
  ro3 = false;
  ro4 = false;
  disabledfir: boolean;
  disabledact: boolean;
  disabledcar: boolean;
  private capasub: Subscription;
  private domicilioSubscription: Subscription;
  private firmantesSubscription: Subscription;
  private actasSubscription: Subscription;
  titulosSecciones = [];
  secciones = {};
  constructor( private eventService: MantContEmit,
               public _mantenimientocontservice: MantenimientoContribuyentesService,
               private usuarioService: UsuarioService,
               private aws: AwsService,
               public _contribuyentesService: ContribuyentesService,
                ) { }
                
  ngOnInit() {
    this.capasub = this.eventService.capafiliacionEmitter.subscribe((data: {idc: number}) => {
      //console.log('data recibida: ', data);
      this.handleFirmanteEvent(data);
    });
  }
  ngOnDestroy(): void { 
    if (this.capasub) {
      this.capasub.unsubscribe();
      //console.log('DESTRUYO COMPONENTE CAPAFILIACION');
    }
    this.unsubscribeFromDomicilioChanges();
    this.unsubscribeFromFirmantesChanges();
    this.unsubscribeFromActasChanges();

}

handleFirmanteEvent(data) { 
      this.idc = '';
      this.idc = data.idc;
      this.ro1 = false;
      this.ro2 = false;
      this.ro3 = false;
      this.ro4 = false;
      this.disabledfir = false;
      this.disabledact = false;
      this.disabledcar = false;
      this.handleDisabledPanels();
}

handleDisabledPanels() {
      this.ro1 = false;
      this.ro2 = false;
      this.ro3 = false;
      this.ro4 = false;
      this.disabledfir = false;
      this.disabledact = false;
      this.disabledcar = false;
  const observable1 = this.aws.getDocuments(this.idc);
  const observable2 = this._mantenimientocontservice.getDirecciones(this.idc);
  const observable3 = this._mantenimientocontservice.getFirmantes(this.idc);
  const observable4 = this.usuarioService.getPublicDeedsByIdc(this.idc).pipe(catchError(error => { return of (error); }));
  const observable5 = this._contribuyentesService.getContributorDetails(this.idc).pipe(catchError(error => { return of (error); }));
  forkJoin([observable1, observable2, observable3, observable4, observable5]).subscribe(async ([documentos, direcciones, firmantes, actas, contdet]) => {
    this.archivoNombresSubidos = documentos.filter((r: { status: string; }) => r.status == 'IN');
    this.archivoNombresSubidos.forEach(doc => {
      if(!this.titulosSecciones.includes(doc.section)){
        this.titulosSecciones.push(doc.section); 
        this.secciones[doc.section] = [] 
      }
      this.secciones[doc.section].push(doc)
      this.secciones[doc.section].sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    })
    let actasres;
    
    if (direcciones.length <= 0) {
      this.disabledfir = true;
    }
    if (firmantes.length <=0) {
      this.disabledact = true;
    } else if(this.disabledfir === true) {
      this.disabledact = true;
    }

    if (!actas.error && actas.data.length > 0) {
     actasres = await this.filtractas(actas);
     if (actasres.length <= 1) {
      this.disabledcar = true;
     } else if (this.disabledact === true) {
      this.disabledcar = true;
     }
    } else {
      this.disabledcar = true;
    }
    if(this.disabledfir === true && this.disabledact === true && this.disabledcar === true) {
      this.toggleElements('ro1');
    } else if (this.disabledfir === false && this.disabledact === true && this.disabledcar === true) {
      this.toggleElements('ro2');
    } else if (this.disabledfir === false && this.disabledact === false && this.disabledcar === true) {
      this.toggleElements('ro3');
    } else if (this.disabledfir === false && this.disabledact === false && this.disabledcar === false) {
      if (contdet.error && contdet.error.error && contdet.error.error.includes('contributor_details')) {
        this.disabledcar = true;
        swal2.fire('Atencion','No se puede dar de alta la caratula ya que no se ah terminado el registro','info');
      } else {
        this.toggleElements('ro4');
      }
      
    }
  });
}

async filtractas(actas: { [x: string]: any[]; }) {
  /* se hace una promesa para resolver cuando se tengan datos en el arreglo*/
  return new Promise(resolve => {
    let actasres = [];
    setTimeout(() => {
      try {
        actas['data'].forEach(d => {
          if (d.company_contributor_id === this.idc) {
            actasres.push(d);
          }
        });
        resolve(actasres);
      } catch (error) {
        resolve(error)
      }
      
    }, 300);
  });
}

toggleElements(ele: string) {
  let animationClassKey: string;
  let stateKey: string;
  switch (ele) {
    case 'ro1':
      stateKey = 'ro1';
      animationClassKey = 'animationClassRo1';
      if (!this[stateKey]) {
        setTimeout(() => {
          this.unsubscribeFromDomicilioChanges();
          this.subscribeToDomicilioChanges();
          if (this.domiciliosComponent && this.ro1) {
            this.eventService.domiciliosEmitter.emit({ idc: this.idc });
          }
        });
      } else {
        this.unsubscribeFromDomicilioChanges();
      }
      break;
    case 'ro2':
      stateKey = 'ro2';
      animationClassKey = 'animationClassRo2';
      if (!this[stateKey]) {
        setTimeout(() => {
          this.unsubscribeFromFirmantesChanges();
          this.subscribeToFirmantesChanges();
          if (this.firmantesComponent && this.ro2) {
            this.eventService.firmantesEmitter.emit({idc: this.idc});
          }
        });
      } else {
        this.unsubscribeFromFirmantesChanges();
      }
      break;
    case 'ro3':
      stateKey = 'ro3';
      animationClassKey = 'animationClassRo3';
      if (!this[stateKey]) {
        setTimeout(() => {
          this.unsubscribeFromActasChanges();
          this.subscribeToActasChanges();
          if (this.actasComponent && this.ro3) {
            this.eventService.actacEmitter.emit({idc: this.idc});
          }
        });
      }
      break;
    case 'ro4':
      stateKey = 'ro4';
      animationClassKey = 'animationClassRo4';
      if (!this[stateKey]) {
        setTimeout(() => {
          if (this.caratulaComponent && this.ro4) {
            this.eventService.caratulaEmitter.emit({idc: this.idc});
          }
        });
      }
      break;
    default:
      return;
  }

  if (this[stateKey]) {
    this[stateKey] = !this[stateKey];
    
  } else {
    this[stateKey] = !this[stateKey];
    
  }
}

private subscribeToDomicilioChanges() {
  if (this.domiciliosComponent && !this.domicilioSubscription) {
    this.domicilioSubscription = this.domiciliosComponent.cambioDetectado.subscribe(() => {
      this.handleDisabledPanels();
    });
  }
}

private unsubscribeFromDomicilioChanges() {
  if (this.domicilioSubscription) {
    this.domicilioSubscription.unsubscribe();
    this.domicilioSubscription = null;
  }
}

private subscribeToFirmantesChanges() {
  if (this.firmantesComponent && !this.firmantesSubscription) {
    this.firmantesSubscription = this.firmantesComponent.cambioDetectado.subscribe(() => {
      this.handleDisabledPanels();
    });
  }
}

private unsubscribeFromFirmantesChanges() {
  if (this.firmantesSubscription) {
    this.firmantesSubscription.unsubscribe();
    this.firmantesSubscription = null;
  }
}

private subscribeToActasChanges() {
  if (this.actasComponent && !this.actasSubscription) {
    this.actasSubscription = this.actasComponent.cambioDetectado.subscribe(() => {
      this.handleDisabledPanels();
    });
  }
}

private unsubscribeFromActasChanges() {
  if (this.actasSubscription) {
    this.actasSubscription.unsubscribe();
    this.actasSubscription = null;
  }
}
//recibo evento de caratula y emito evento para mantcontmain
vdcaratula(event) {
  this.childEvent.emit({clk: event.clk, check: event.check})
}

}