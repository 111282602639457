import { Component, EventEmitter, OnInit } from '@angular/core';
import { OptionsService } from '../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit {
  showac = false;
  edOption = true;
  agregarOpcion = false;
  actualizarOpcionEmitter = new EventEmitter<Object>();

  constructor(
               public _optionsservice: OptionsService,
               public router: Router,
               public http: HttpClient ) { }

  token = localStorage.getItem('token');
  options: any[] = [];
  cols: any[];
  selectedFac: any[];
  tieneacceso = false;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  items: any[] = [];
  searchTerm: string = '';
  filaSeleccionada: number | null = null;
  ngOnInit() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
 });
    swal2.showLoading();
   // this.getAcceso('/options');
    this._optionsservice.getOptions().subscribe( resp => { this.options = resp; swal2.close(); }
    , (err) => {swal2.fire({title: 'Ocurrio un error al cargar la información', allowOutsideClick: false })} );

    this.cols = [

      { field: 'id', header: 'ID' },
      { field: 'name', header: 'Nombre' },
      { field: 'description', header: 'Descripcion' },
      { field: 'group', header: 'Grupo' },
      { field: 'url', header: 'URL' },

  ];

  }

  ngOnDestroy(): void { 
    this.actualizarOpcionEmitter.unsubscribe();

} 

  get filteredData(): any[] {

    return this.options.filter(item =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.group.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.url.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  seleccionarFila(index: number, currentPage: number, itemsPerPage: number) {
    this.filaSeleccionada = index + (currentPage - 1) * itemsPerPage;
  }

  acOpcion(id: any, nom: any) {
    this.showac = true;
    this.edOption = false;
    this.agregarOpcion = false;
    setTimeout(() => {
      this.actualizarOpcionEmitter.emit({id: id});
    }, 100);   
  }

  agu() {
    this.agregarOpcion = true;
  }

  pru() {
    this.edOption = !this.edOption;
    this.agregarOpcion = false;
  }

  pgone() {
    this.currentPage = 1;
    this.filaSeleccionada = -1;
  }

  cierraregusr(event) {
    this.showac = false;
    this.edOption = true;
    this.agregarOpcion = false;
    this.ngOnInit();
  }

  getAcceso(url) {
    let tieneacceso = false;
    this._optionsservice.getOptionsxUsuario(localStorage.getItem('id')).subscribe(resp2 => {
      // tslint:disable-next-line: forin
      for (const j in resp2 ) {
          if ( resp2[j].url === url ) {
            tieneacceso = true;
            break;
          }
        }
      if (!tieneacceso) {
        this.router.navigate(['/accesodenegado']);
      }
    });
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  borraOption( id: string ) {
    swal2.showLoading();
    swal2.fire({
      title: 'Desea Eliminar la opcion',
      text: 'Seleccionada',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }). then ( resp => {
      if ( resp.value) {

        this._optionsservice.borrarOption( id ).subscribe( () => {
          swal2.close();
          swal2.fire({
            title: 'La opcion',
            text: 'fue eliminada con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              this.ngOnInit();
            }

          } );

        }, (err) => {
          swal2.close();
          console.log(err);
          swal2.fire({
            title: 'Ocurrio un error',
            text: '',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              this.ngOnInit();
            }
          } );
         } );

      }
    });

  }

}
