import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUrl = state.url;  // obtengo la url de donde estoy
    const redirectUrl = this.getRedirectUrl();

    // una verificacion para evitar loops infinitos
    if (redirectUrl && currentUrl !== redirectUrl) {
      this.router.navigate([redirectUrl]);
      return false;  // regreso false para evaluar que no se active la ruta pues estoy donde mismo
    }

    return true;  // regreso true para evaluar que se active la ruta
  }

  private getRedirectUrl(): string | null {
    // para redireccionar dependiendo de la variable de ambiente
    switch (environment.Ambiente.toLowerCase()) {
      //ambientes trinitas
      case 'trinitasProd'.toLowerCase():
        return '/logintri';
      case 'trinitasTest'.toLowerCase():
        return '/logintri';
      case 'trinitasTestAU'.toLowerCase():
        return '/logintri';
      //ambiente factor
      case 'ProdFactoraje'.toLowerCase():
        return '/login';
      case 'TestFactoraje'.toLowerCase():
        return '/login';
      case 'TestFactorajeAU'.toLowerCase():
        return '/login';
      case 'ProdFactorajeAU'.toLowerCase():
        return '/login';
        //ambientes rio yaqui
      case 'yaquiTest'.toLowerCase():
        return '/loginry';
      case 'yaquiTestAU'.toLowerCase():
        return '/loginry';
      case 'yaquiProd'.toLowerCase():
        return '/loginry';
      //ambiente finantah
      case 'finantahTest'.toLowerCase():
        return '/logintah';
      case 'finantahProd'.toLowerCase():
        return '/logintah';
      default:
        return null;  // no redirigir a ningun lado y puedo entrar a todo
    }
  }
}