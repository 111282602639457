import { Component, Input, OnInit } from '@angular/core';
import { StpService } from '../../../services/stp/stp.service';
import { FundersService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
import { Router } from '@angular/router';
import { ParametrosGeneralesService } from '../../../services/parametrosgenerales/parametrosgenerales.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-saldos-stp',
  templateUrl: './saldos-stp.component.html',
  styles: []
})
export class SaldosStpComponent implements OnInit {

  constructor(private _stpservice: StpService,
              private _fundersservice: FundersService,
              private parametrosService: ParametrosGeneralesService) { }

  saldo = 0;
  token = localStorage.getItem('token');

  state;
  newState;
  preState;
  checkState = false;
  facturas: any[] = [];
  funders_banks: any[] = [];
  funders_data: any[] = [];
  suppliers_banks: any[] = [];
  suppliers_data: any[] = [];
  usuario: string;
  essupplier: boolean;
  cols: any[];
  colspdf: any[];
  contributors_info:any;

  selectedFac: any[];
  router: Router;
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];
  selectedInvoices = [];

  monto: any;
  banco:any;
  concepto:any;
  nombre_beneficiario:any;
  cuenta_destino:any;
  institucion:any;
  cuenta_final:any;
  beneficiario:any[];
  referencia:any;
  transferencia:any;
  saldo_contributor:any;

  nombre_ordenante = '';
  tipo_cuenta_ordenante = '';
  cuenta_ordenante = '';
  forma:FormGroup;

  _selectedColumns: any[];

  ngOnInit() {

      this.forma = new FormGroup({
        institucion_origen: new FormControl( null , Validators.required ),
        cuenta_origen: new FormControl( null , Validators.required ),
        // monto: new FormControl( null , Validators.required ),
        institucion: new FormControl( null , Validators.required ),
        cuenta_destino: new FormControl( null , Validators.required ),
        // referencia: new FormControl( null , Validators.required ),
        contributor_data: new FormControl( null , Validators.required ),
      });
    this.get_contributors_stp_info();


    this.parametrosService.getGeneralParam('NOMBRE_ORDENANTE').subscribe(resp => {
      this.nombre_ordenante = resp['value'];

      this.parametrosService.getGeneralParam('CUENTA_ORIGEN_STP').subscribe(resp => {
        this.cuenta_ordenante = resp['value'];

        this.parametrosService.getGeneralParam('TIPO_CUENTA_ORDENANTE').subscribe(resp => {
          this.tipo_cuenta_ordenante = resp['value'];

          this.consulta_saldo_stp();
        })
      })
    })
  }

  get_contributors_stp_info(){
    this._stpservice.getContributorsWithStpAccounts()
    .subscribe(resp =>{
        try{
        this.contributors_info = resp['stp_accounts'];
      }catch{
        swal2.fire('Atención', 'Hubo un problema en la consulta de saldo', 'info');
        return
      }
    })
  }

  consulta_saldo_stp(){
    const params = {
      empresa: this.nombre_ordenante,
      cuentaOrdenante: this.cuenta_ordenante
    };
    this._stpservice.getStpAccountBalanceStp(params)
    .subscribe(resp => {
      try{
        this.saldo = resp['response']['respuesta']['saldo'];
        swal2.close();
      }catch{
        swal2.fire('Atención', 'Hubo un problema en la consulta de saldo', 'info');
        return
      }
      
    } , err => {
      console.log(err)
      swal2.fire({title: 'Ocurrio un error al cargar la información', allowOutsideClick: false })} );
  }

  consulta_saldo_contributor_stp(){
    const params = {
      empresa: this.nombre_ordenante,
      cuentaOrdenante: this.cuenta_final
    };
    console.log("params")
    console.log(params)
    swal2.fire('Espere, por favor')
    swal2.showLoading();
    this._stpservice.getStpAccountBalanceStp(params)
    .subscribe(resp => {
      try{
        this.saldo_contributor = resp['response']['respuesta']['saldo'];
        swal2.close();
      }catch{
        swal2.fire('Atención', 'Hubo un problema en la consulta de saldo', 'info');
        swal2.close();
        return
      }
      
    } , err => {
      console.log(err)
      swal2.fire({title: 'Ocurrio un error al cargar la información', allowOutsideClick: false })} );
  }

  set_account_contributor_stp(contributor){
    this.cuenta_final = '';
    this.institucion = '';
    this.nombre_beneficiario = contributor.options[contributor.selectedIndex].text
    this.cuenta_destino = this.contributors_info.find( cuenta => cuenta.contributor_id == parseInt(contributor.options[contributor.selectedIndex].value));
    this.cuenta_final = this.cuenta_destino['stp_account']
    this.institucion = 'FLIEBEN3'
  }

}
