import { Component, OnInit } from '@angular/core';
import { UsuarioService, PrivilegiosUsuariosService, OptionsService, PagosService } from '../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import { map, startWith } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { AwsService } from 'src/app/services/awsser/aws.service';


@Component({
  selector: 'app-usuarioscadenas',
  templateUrl: './usuarioscadenas.component.html',
  styleUrls: ['./usercompanies.component.css']
})
export class UsuariosCadenasComponent implements OnInit {myControl = new FormControl();
  filteredCadenas: Observable<any[]>;
  cadenaControl = new FormControl('');
  usuarios: any[] = [];
  cadenas: any[] = []; // Inicializa cadenas como una lista vacía
  cols: any[];
  selectedFac: any[];
  terminoBusqueda: string = '';
  constructor( public _usuarioservice: UsuarioService,
               public _privilegiosusuarios: PrivilegiosUsuariosService,
               public _optionsservice: OptionsService,
               public router: Router,
               public _pagosservice: PagosService,
               public http: HttpClient,
               public awsService: AwsService) {}

              private _filter(value: string): any[] {
                const filterValue = value.toLowerCase();
                return this.cadenas.filter(cadena => cadena.nombre_cadena.toLowerCase().includes(filterValue));
              }

  

  ngOnInit() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();
    // Restablecer el filtro a una cadena vacía en ngOnInit
    this.cadenaControl.setValue('');
    const observable1 = this._usuarioservice.getUsuarios();
    const observable2 = this._pagosservice.getCadenas();
    forkJoin([observable1, observable2]).subscribe(async ([usuarios, cadenas]) => {
      await this.getCadenaUsuario(usuarios);
      for (const prep in usuarios) {
        let cadenaEncontrado = false; // Bandera para verificar si se encontró un cadena para el usuario actual
        for (const prop in cadenas) {
          if (cadenas[prop].id_cadena === usuarios[prep].companyId) {
            usuarios[prep].nombreCadena = cadenas[prop].nombre_cadena;
            cadenaEncontrado = true;
            break; // Salir del bucle interno una vez que se encuentre un cadena para el usuario
          }
        }
        // Si no se encontró ningún cadena para el usuario, establecer nombreCadena en null
        if (!cadenaEncontrado) {
          usuarios[prep].nombreCadena = null;
        }
      }
      this.usuarios = usuarios;
      this.cadenas = cadenas;
      this.filteredCadenas = this.cadenaControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre_cadena),
        map(name => name ? this._filter(name) : this.cadenas.slice())
      );
      setTimeout(() => {
        swal2.close();
      }, 3);
      
    }, (err) => {
      swal2.fire({
        title: 'Atención', 
        text: 'Algo salió mal, contacte al administrador', 
        icon: 'info',
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(res => {
        if(res.value){
          // this.ngOnInit();
          swal2.close();
        }
      });
    });

  }

  displayFn(cadena?: any): string | undefined {
    return cadena ? cadena.nombre_cadena : undefined;
  }

  async getCadenaUsuario(usuarios) {
    try {
      const promises = [];
      for (const prop in usuarios) {
        const promise = new Promise<void>((resolve, reject) => {
          this.awsService.getCompanyByUser(usuarios[prop].id).subscribe(resp => {
            // this._pagosservice.getCompanyUser(usuarios[prop].id).subscribe(resp => {
            if (resp.length > 0) {
              usuarios[prop].companyId = resp[0].company_id;
              usuarios[prop].companyUserId = resp[0].company_user_id;
            } else {
              usuarios[prop].companyId = null;
              usuarios[prop].companyUserId = null;
            }
            resolve();
          });
        });
        promises.push(promise);
      }
  
      await Promise.all(promises);
      
      return usuarios;
    } catch (error) {
      console.error('Error en getCadenaUsuario:', error);
      throw error;
    }
  }

  asignaCadena(idusu: any, nusu: string, prov: any) {
    console.log(prov)
    const idCadena = prov.id_cadena;
    const nombreCadena = prov.nombre_cadena;

    if (idCadena == undefined || idCadena == '') {
      swal2.fire('Debe seleccionar un cadena de la lista','','info');
      return;
    }

    swal2.fire({
      title: 'Desea asignar el cadena: ' + nombreCadena + ' al usuario: '+nusu, 
      text: '', 
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }).then(res => {
      if(res.value){
        const params = {
          token: '',
          secret_key: '',
          user_id: idusu,
          company_id: idCadena
        };
        this._pagosservice.postUsuarioCadena(params).subscribe( () => {
          swal2.close();
          swal2.fire({
            title: 'El cadena fue asignado',
            text: 'Con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
    
            if ( res.value ) {
              this.ngOnInit();
            }
    
          } );
    
         }, (err) => {
          swal2.close();
          console.log(err);
          swal2.fire(
               'Error al guardar',
               err.error.errors[0],
               'error'
            );
         } );
      } else {
        this.ngOnInit();
      }
    });
  }

  filtrarDatos() {
    return this.usuarios.filter(item =>
      item.name.toLowerCase().includes(this.terminoBusqueda.toLowerCase()) ||
      item.email.toLowerCase().includes(this.terminoBusqueda.toLowerCase())
    );
  }

  borraCadena(item: any) {
    swal2.fire({
      title: 'Desea eliminar la cadena?', 
      text: '', 
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }).then(res => {
      if(res.value){
        this._pagosservice.borraCompanyUser(item).subscribe(
          resp2 => {
           swal2.fire({
             title: 'El cadena fue eliminado',
             text: 'Con exito',
             icon: 'success',
             showConfirmButton: true,
             showCancelButton: false,
             allowOutsideClick: false
           }). then ( res => {
             if ( res.value ) {
               this.ngOnInit();
             }

           } );
          }, (err) => {
           swal2.close();
           console.log(err);
           swal2.fire(
                'Error al eliminar',
                err.error.errors[0],
                'error'
             );
          }
        );
      }
    });
  }


}
