import { Component, OnInit, Input, EventEmitter, OnDestroy  } from '@angular/core';
import { ContribuyentesService, OptionsService, MantenimientoContribuyentesService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-escrituras',
  templateUrl: './escrituras.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class EscriturasComponent implements OnInit, OnDestroy  {
  @Input() eventEmitter: EventEmitter<string>;
  message: string;
  idc = 3;
  escrituras;
  escritura;
  iddp = '';
  showModal: boolean;
  nesc = false;
  constructor(
               public _contribuyentesService: ContribuyentesService,
               public _optionsservice: OptionsService,
               public router: Router,
               public _mantenimientocontservice: MantenimientoContribuyentesService,
               public http: HttpClient ) { }

  ngOnInit() {
    this.subscribeToParentEmitter();
  }
  ngOnDestroy(): void { 
    this.eventEmitter.unsubscribe();
    //console.log('DESTRUYO COMPONENTE ESCRITURAS')

} 
  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe(d => {//console.log(d)
      this.nesc = false;
      this.escritura = [];
      this.escrituras = [];
      this.idc = d.idc;
      this._mantenimientocontservice.getEscrituras( d.idc ).subscribe( resp => {this.escrituras = resp; console.log(resp) } );
    })
    
}

borromodocreo() {
  this.nesc = false;
  this.escritura = [];
  this.escrituras = [];
  this._mantenimientocontservice.getEscrituras( this.idc ).subscribe( resp => {this.escrituras = resp; console.log(resp) } );
}

nescr() {
this.nesc = !this.nesc;
}

show() {
  this.showModal = true; // Show-Hide Modal Check
}
hide() {
  this.showModal = false;
}

getescritura() {
  const escritura: any = document.getElementById('escritura');
  const valorescritura = escritura.options[escritura.selectedIndex].value;
  if (valorescritura !== 'novalor') {
  let ide = '';
  for ( const prop in this.escrituras ) {
    if (this.escrituras[prop].document_type.trim() === valorescritura.trim()) {
      ide = this.escrituras[prop].id;
    }
  }
  this._mantenimientocontservice.getEscritura( this.idc, ide ).subscribe( resp => {this.escritura = resp; console.log(resp) } );
} else {
  this.escritura = [];
}
}

borraescritura( item ) {
  swal2.showLoading();
  swal2.fire({
    title: 'Desea elminiar la escritura seleccionada',
    text: '?',
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }). then ( resp => {
    if ( resp.value) {

      this._mantenimientocontservice.borraEscritura( item.contributor_id, item.id ).subscribe( () => {
        swal2.close();
        swal2.fire({
          title: 'La escritura se elimino',
          text: 'Con exito',
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }). then ( res => {
          if ( res.value ) {
            this.borromodocreo();
          }
        } );
            // window.location.reload();
}, (err) => {
swal2.close();
console.log(err);
swal2.fire({
  title: 'Ocurrio un error',
  text: '',
  icon: 'error',
  showConfirmButton: true,
  showCancelButton: false,
  allowOutsideClick: false
}). then ( res => {
  if ( res.value ) {
    this.borromodocreo();
  }
} );
});

    }
  });

}

guardaEscritura() {
  swal2.showLoading();
  const d = new Date((document.getElementById('dppd_datep')as HTMLInputElement).value);
  d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
      month = '0' + month;
  }
  if (day.length < 2) {
      day = '0' + day;
  }

  const fechaesc = [year, month, day].join('-');

  const params = {

  document_type: (document.getElementById('dpdocument_typep')as HTMLInputElement).value,
  description: (document.getElementById('dpdescriptionp')as HTMLInputElement).value,
  pd_number: (document.getElementById('dppd_numberp')as HTMLInputElement).value,
  pd_book: (document.getElementById('dppd_bookp')as HTMLInputElement).value,
  pd_date: fechaesc,
  rug: (document.getElementById('dprugp')as HTMLInputElement).value,
  document: (document.getElementById('dpdocumentp')as HTMLInputElement).value,
  token: '',
  secret_key: ''

  };

  this._contribuyentesService.crearDP(this.idc, params).subscribe( resp => { swal2.close();
                                                                             this.iddp = resp;
                                                                             swal2.fire({
                                                                              title: 'Los datos se guardaron',
                                                                              text: 'Con exito',
                                                                              icon: 'success',
                                                                              showConfirmButton: true,
                                                                              showCancelButton: false,
                                                                              allowOutsideClick: false
                                                                              }). then ( res => {
                                                                              if ( res.value ) {
                                                                              this.borromodocreo();
                                                                              }
                                                                              } );
                                                                              }, (err) => {
                                                                                swal2.close();
                                                                                console.log(err);
                                                                                swal2.fire({
                                                                                  title: 'Ocurrio un error',
                                                                                  text: '',
                                                                                  icon: 'error',
                                                                                  showConfirmButton: true,
                                                                                  showCancelButton: false,
                                                                                  allowOutsideClick: false
                                                                                }). then ( res => {
                                                                                  if ( res.value ) {
                                                                                    this.borromodocreo();
                                                                                  }
                                                                                } );
                                                                               });
}

modificaEscritura( item ) {
  swal2.showLoading();
  const d = new Date(item.pd_date);
  d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
      month = '0' + month;
  }
  if (day.length < 2) {
      day = '0' + day;
  }

  const fechaesc = [year, month, day].join('-');

  const params = {

  document_type: item.document_type,
  description: item.description,
  pd_number: item.pd_number,
  pd_book: item.pd_book,
  pd_date: fechaesc,
  rug: item.rug,
  document: item.document,
  token: '',
  secret_key: ''
  };

  console.log(item.contributor_id);
  console.log(item.id)
  console.log(params)

  this._mantenimientocontservice.modificaEscritura(item.contributor_id, item.id , params).subscribe( resp => { swal2.close();
                                                                                                swal2.fire({
      title: 'Los datos se modificaron',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {

      if ( res.value ) {
        this.borromodocreo();
      }

    } );

}, (err) => {
swal2.close();
console.log(err);
swal2.fire({
  title: 'Ocurrio un error',
  text: '',
  icon: 'error',
  showConfirmButton: true,
  showCancelButton: false,
  allowOutsideClick: false
}). then ( res => {
  if ( res.value ) {
    this.borromodocreo();
  }
} );
});

}
}