import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FacturasService, ReportesService } from 'src/app/services/service.index';
import Swal from 'sweetalert2';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-revision-facturas-finantah',
  templateUrl: './revision-facturas-finantah.component.html',
  styleUrls: ['./revision-facturas-finantah.component.css']
})
export class RevisionFacturasFinantahComponent implements OnInit {

  facturas = [];
  cargado = false;
  cols = [
    { field: 'folio_factura', header: 'Número de Factura' },
    { field: 'rfc_receptor', header: 'RFC Receptor' },
    { field: 'receptor', header: 'Receptor' },//----------------------
    { field: 'total_factura', header: 'Total' },
    { field: 'fecha_emision', header: 'Fecha de Factura' },
    { field: 'fecha_vencimiento', header: 'Fecha de pago' },
    { field: 'tasa', header: 'Tasa' },
    { field: 'comision', header: 'Comisión' },
    { field: 'pdf', header: 'PDF' },
  ];
  reporte = [];
  aceptadas = [];
  rechazadas = [];
  listo = false;

  constructor(private facturasService: FacturasService,
    private reportesService: ReportesService) { }

  ngOnInit() {
    this.facturas = [];
    this.reporte = [];
    this.aceptadas = [];
    this.rechazadas = [];
    this.listo = false;

    this.facturasService.getInvoicesFinantah().subscribe(resp => {
      this.facturas = resp;
      this.cargado = true;
    })
  }

  almacenarAceptada(rowData){
    this.aceptadas.push(rowData);
    let i = this.facturas.findIndex(f => f.id == rowData.id);
    this.facturas.splice(i, 1);
    if(this.facturas.length == 0){
      this.procesar();
    }
  }

  almacenarRechazada(rowData){
    Swal.fire({
      title: 'Describe la razón del rechazo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if(result.value && result.value != ''){
        rowData['nota_rechazo'] = result.value;
        this.rechazadas.push(rowData);
        let i = this.facturas.findIndex(f => f.id == rowData.id);
        this.facturas.splice(i, 1);
        if(this.facturas.length == 0){
          this.procesar();
        }
      }else{
        swal2.fire('Error', 'Debe teclear una razón válida', 'info');
      }
    })
  }

  procesar(){
    this.aceptar();
    
    if(this.rechazadas.length > 0){
      this.rechazar();
    }

  }

  async aceptar(){
    swal2.showLoading();
    for await (let a of this.aceptadas) {
      var params = {invoice_id: a.id, rate: a.tasa, fee: a.comision};
      let response = await this.procesaAceptadas(params);
      if (response.msg === "OK") {
        /* console.log('a', this.aceptadas.length, this.aceptadas);
        var n = this.aceptadas.find(n => a == n);
        console.log(n);
        this.aceptadas.splice(n, 1);
        console.log('d', this.aceptadas.length, this.aceptadas);
        if(this.aceptadas.length == 0){
          swal2.close();
        } */
      } else if (response.msg === "Error") {
        console.log(a, response);
      }
    }
    swal2.close();
    this.ngOnInit();
  }

  async procesaAceptadas(params){
    try {
      const r = await (await this.facturasService.updateRateFee(params)).toPromise();
      let resp = {
        status: true,
        resp: r,
        msg: "OK",
        nombre: params.invoice_id,
      };
      return resp;
    } catch (error) {
      let resp = {
        status: false,
        resp: error,
        msg: "Error",
        nombre: params.invoice_id,
      };
      return resp;
    }
  }

  rechazar(){
    let params = {};
    let invoices = [];
    this.rechazadas.forEach(r => {
      invoices.push({id: r.id, note: r.nota_rechazo})
    });
    params['invoices'] = invoices;
    params['company_id'] = (document.getElementById('company') as HTMLInputElement).value;
    this.facturasService.rejectInvoices(params).subscribe(resp => {
      swal2.fire('Éxito', 'Facturas rechazadas correctamente', 'info');
      this.ngOnInit();
    }, err => {
      console.log(err);
      swal2.fire('Error', 'Contacte al administrador', 'info');
    });
    /*
    this.facturasService.deleteInvoice(rowData.id, {notes: result.value}).subscribe(resp => {
          swal2.fire('Éxito', 'Factura rechazada', 'success');
          this.ngOnInit();
        }, err => {
          console.log(err)
          swal2.fire('Atención', err.error.errors[0], 'info');
        }) 
     */

  }

}
