import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContribuyentesService, FundersService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Key } from 'readline';

declare function init_plugins();

@Component({
  selector: 'app-creacreditline',
  templateUrl: './creacreditline.component.html',
  styles: []
})
export class CreaCreditlineComponent implements OnInit {

  forma: FormGroup;
  idl: string;
  cadena: any[] = [];
  comanysector: any[] = [];
  lineaPesos: any [] = [];
  lineaDolares: any[] = [];
  nuevopesos = true;
  nuevodolares = true;
  validate_rebate = false;
  respvacio = {credit_available: '', credit_limit: '', rate: '', has_rebate: '', rent_percentage:'', rebate_percentage:'' };
  rebateValue: any;
  rebatePercentageValue: any;
  rentPercentageValue: any;
  fondeador = true;
  cadenasfondeadores: any[] = [];
  debtype: any[] = [];
  cols: any[];
  lineascredito: any[] = [];
  constructor(
    private route: ActivatedRoute,
    public _contribuyentesService: ContribuyentesService,
    public _fundersService: FundersService,
    public router: Router
  ) { }


  ngOnInit() {
      Swal.showLoading();
      this.lineaPesos = [];
      this.lineaDolares = [];
      this.nuevopesos = true;
      this.nuevodolares = true;
      this.validate_rebate = false;
      this.fondeador = true;
      this.idl = this.route.snapshot.paramMap.get('id');
      this._contribuyentesService.getFundingCreditLineType().subscribe(resp => {
      if ( resp.value === 'FONDEADOR' ) {
      this.fondeador = true;
      } else if ( resp.value === 'CADENA' ) {
      this.fondeador = false;
      this._contribuyentesService.getCadenasParaFondeador().subscribe( resp => {this.cadenasfondeadores = resp
      } )
      this._contribuyentesService.getDebType().subscribe(resp => {this.debtype = resp;})
      this._contribuyentesService.getCreditLines(this.idl).subscribe(resp => {
      this.lineascredito = resp;
      for (const prop in this.lineascredito) {
        if (this.lineascredito[prop].company_id === null) {
          this.lineascredito.splice(parseInt(prop),1);
        }
      }
      for (const prop in this.lineascredito) {
        this._contribuyentesService.getCompanyName(this.lineascredito[prop].company_id).subscribe( resp => {
          this.lineascredito[prop].nombrecadena = resp.data.attributes.business_name;
        } )
      }
      })
      } else {
      this.fondeador = true;
      }
      }) 
      this._fundersService.getCreditLinexFunder(this.idl).subscribe( resp => {

        if (resp.length > 0) {
        for (const prop in resp) {
          if (resp[prop].company_id === null ) {
            if (resp[prop].currency === 'PESOS') {
              this.lineaPesos.push(resp[prop]);
              this.nuevopesos = false;
            } else if (resp[prop].currency === 'DOLARES') {
              this.lineaDolares.push(resp[prop]);
              this.nuevodolares = false;
            }
          }
          
        }
      }
        if (this.lineaPesos.length === 0) {
          this.lineaPesos.push(this.respvacio);
      }
        if (this.lineaDolares.length === 0) {
        this.lineaDolares.push(this.respvacio);
    }
        Swal.close();
      } );
      this.forma = new FormGroup({
        credit_limit: new FormControl( null , Validators.required ),
        credit_available: new FormControl( null , Validators.required ),
        rate: new FormControl( null , Validators.required ),
        has_rebate: new FormControl( null , Validators.required ),
        rebate: new FormControl( null , Validators.required ),
        rent_percentage: new FormControl( null , Validators.required ),
        rebate_percentage: new FormControl( null , Validators.required ),
        credit_limitd: new FormControl( null , Validators.required ),
        credit_availabled: new FormControl( null , Validators.required ),
        rated: new FormControl( null , Validators.required ),
      } );

      this.cols = [

        { field: 'credit_limit', header: 'Limite' },
        { field: 'credit_available', header: 'Crédito disponible' },
        { field: 'rate', header: 'Tasa de interes' },
        { field: 'company_id', header: 'Cadena' },
        { field: 'debt_type', header: 'Tipo cobranza' },
        { field: 'has_rebate', header: 'Modo rebate' },
        // { field: 'rebate', header: 'Rebate' },
        { field: 'rebate_percentage', header: 'Porcentaje de Rebate Cadena' },
        { field: 'rent_percentage', header: 'Porcentaje de Renta' },
        { field: 'herramientas', header: 'Herramientas' }
    ];

  }


  guardaPesos() {
    this.evalRebate();
    this.evalRentPercentage();
    console.log("this.rebatePercentageValue")
    console.log(this.rebatePercentageValue)
    const params = {
    token: '',
    secret_key: '',
    funder_id: this.idl,
    currency: 'PESOS',
    credit_limit: (document.getElementById('credit_limit') as HTMLInputElement).value,
    credit_available: (document.getElementById('credit_available') as HTMLInputElement).value,
    rate: (document.getElementById('rate') as HTMLInputElement).value,
    rebate: this.rebateValue,
    rebate_percentage: this.rebatePercentageValue,
    rent_percentage: (document.getElementById('rent_percentage') as HTMLInputElement).value,
    has_rebate: (document.querySelector('input[name = "has_rebate"]:checked') as HTMLInputElement).value,
  };
    this._fundersService.creaCreditLinePesosDolares( this.idl, params).subscribe( () => {
    Swal.fire(
      'Creacion de Linea de credito',
      'Exitosa',
      'success'
   ).then( 
    // () => window.location.reload() 
    ) 
   }, (err) => {         console.log(err);
                                                      Swal.fire(
                              'Error al crear La linea de credito',
                              'Error',
                              'error'
                           );
                        } );
  }

  guardaDolares() {
    this.evalRebate();
    this.evalRentPercentage();
    const params = {
    token: '',
    secret_key: '',
    funder_id: this.idl,
    currency: `DOLARES`,
    credit_limit: (document.getElementById('credit_limitd') as HTMLInputElement).value,
    credit_available: (document.getElementById('credit_availabled') as HTMLInputElement).value,
    rate: (document.getElementById('rated') as HTMLInputElement).value,
    rebate: this.rebateValue,
    rebate_percentage: this.rebatePercentageValue,
    rent_percentage: (document.getElementById('rent_percentage') as HTMLInputElement).value,
    has_rebate: (document.querySelector('input[name = "has_rebate"]:checked') as HTMLInputElement).value,
  };
    this._fundersService.creaCreditLinePesosDolares( this.idl, params).subscribe( () => {
    Swal.fire(
      'Creacion de Linea de credito',
      'Exitosa',
      'success'
   ).then( () => window.location.reload() )
      }, (err) => {         console.log(err);
                            Swal.fire(
                              'Error al crear La linea de credito',
                              'Error',
                              'error'
                           );
                        } );
  }

  modificapesos(idll) {
    this.evalRebate();
    this.evalRentPercentage();
    const params = {
      token: '',
      secret_key: '',
      currency: 'PESOS',
      credit_limit: (document.getElementById('credit_limit') as HTMLInputElement).value,
      credit_available: (document.getElementById('credit_available') as HTMLInputElement).value,
      rate: (document.getElementById('rate') as HTMLInputElement).value,
      rebate: this.rebateValue,
      rebate_percentage: this.rebatePercentageValue,
      rent_percentage: (document.getElementById('rent_percentage') as HTMLInputElement).value,
      has_rebate: (document.querySelector('input[name = "has_rebate"]:checked') as HTMLInputElement).value,
    };
    this._fundersService.modificaCreditLinePesosDolares( this.idl, idll, params).subscribe( () => {
        Swal.fire(
          'Modificacion de Linea de credito',
          'Exitosa',
          'success'
       ).then( () => this.ngOnInit() ) }, (err) => {         console.log(err);
                                Swal.fire(
                                  'Error al modificar La linea de credito',
                                  'Error',
                                  'error'
                               );
                            } );
  }

  modificadolares(idll) {
    this.evalRebate();
    this.evalRentPercentage();
    const params = {
      token: '',
      secret_key: '',
      currency: `DOLARES`,
      credit_limit: (document.getElementById('credit_limitd') as HTMLInputElement).value,
      credit_available: (document.getElementById('credit_availabled') as HTMLInputElement).value,
      rate: (document.getElementById('rated') as HTMLInputElement).value,
      rebate: this.rebateValue,
      rebate_percentage: this.rebatePercentageValue,
      rento_percentage: (document.getElementById('rent_percentage') as HTMLInputElement).value,
      has_rebate: (document.querySelector('input[name = "has_rebate"]:checked') as HTMLInputElement).value,
    };
    this._fundersService.modificaCreditLinePesosDolares( this.idl, idll, params).subscribe( () => {
        Swal.fire(
          'Modificacion de Linea de credito',
          'Exitosa',
          'success'
       ).then( () => this.ngOnInit() ) }, (err) => {         console.log(err);
                                Swal.fire(
                                  'Error al modificar La linea de credito',
                                  'Error',
                                  'error'
                               );
                            } );
  }

  borrapesos(idll) {
    Swal.fire({
      title: 'Desea eliminar la linea de credito?',
        text: '',
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this._fundersService.borraCeditLinePesosDolares(this.idl, idll).subscribe( resp => { Swal.fire({
            title: 'La linea de credito se elimino',
            text: 'Con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res2 => {
            if ( res2.value ) {
              this.ngOnInit();
            }
          } ); }, (err) => {
            console.log(err);
            Swal.fire({
              title: 'Ocurrio un error',
              text: err.error.errors[0],
              icon: 'error',
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false
            }). then ( res1 => {
              if ( res1.value ) {
                this.ngOnInit();
              }
            } );
           } );
      }
    });
  }


  guardaPesosCadenas() {
    this.evalRebate();
    this.evalRentPercentage();
    const params = {
      token: '',
      secret_key: '',
      funder_id: this.idl,
      currency: 'PESOS',
      credit_limit: (document.getElementById('credit_limit_c') as HTMLInputElement).value,
      credit_available: (document.getElementById('credit_available_c') as HTMLInputElement).value,
      rate: (document.getElementById('rate_c') as HTMLInputElement).value,
      rebate: this.rebateValue,
      rebate_percentage: this.rebatePercentageValue,
      rent_percentage: (document.getElementById('rent_percentage') as HTMLInputElement).value,
      has_rebate: (document.querySelector('input[name = "has_rebate"]:checked') as HTMLInputElement).value,
      company_id: (document.getElementById('cadena_c') as HTMLInputElement).value,
      debt_type: (document.getElementById('debtype_c') as HTMLInputElement).value,
    };
      this._fundersService.creaCreditLinePesosDolares( this.idl, params).subscribe( () => {
      Swal.fire(
        'Creacion de Linea de credito',
        'Exitosa',
        'success'
     ); window.location.reload(); }, (err) => {         console.log(err);
                                                        Swal.fire(
                                'Error al crear La linea de credito',
                                'Error',
                                'error'
                             );
                          } );
  }

  evalRebate(){
    if((document.getElementById('rebate_percentage') as HTMLInputElement)){
      this.rebatePercentageValue = (document.getElementById('rebate_percentage') as HTMLInputElement).value
    }else{
      this.rebatePercentageValue = 0;
    }
  }

  evalRentPercentage(){
    if((document.getElementById('rent_percentage') as HTMLInputElement)){
      this.rentPercentageValue = (document.getElementById('rent_percentage') as HTMLInputElement).value
    }else{
      this.rentPercentageValue = 0;
    }
  }

  clickcheckbox(value){
    if ( value == 'true' ) {
      this.validate_rebate = true
    } else {
      this.validate_rebate = false
    }
  }

  borraLineaCreditoPesosCadenas ( idl ) {
    Swal.fire({
      title: 'Desea eliminar la linea de credito?',
        text: '',
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this._fundersService.borraCeditLinePesosDolares(this.idl, idl).subscribe( resp => { Swal.fire({
            title: 'La linea de credito se elimino',
            text: 'Con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res2 => {
            if ( res2.value ) {
              this.ngOnInit();
            }
          } ); }, (err) => {
            console.log(err);
            Swal.fire({
              title: 'Ocurrio un error',
              text: err.error.errors[0],
              icon: 'error',
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false
            }). then ( res1 => {
              if ( res1.value ) {
                this.ngOnInit();
              }
            } );
           } );
      }
    });
  }
}
