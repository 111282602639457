import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: any): string {
    if (value === null || value === undefined) {
      return '';
    }

    // Convertir a número si es un string
    let numberValue: number = typeof value === 'string' ? parseFloat(value) : value;

    // Verificar si es un número válido
    if (isNaN(numberValue)) {
      return '';
    }

    // Redondear a dos decimales
    numberValue = Math.round(numberValue * 100) / 100;

    // Formatear con comas
    return numberValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}
