import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Rutas
import { APP_ROUTES } from './app.routes';

// Modulos
import { PagesModule } from './pages/pages.module';

// temporal
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Servicios
import { ServiceModule } from './services/service.module';

// Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LoginBaseComponent } from './loginbase/loginbase.component';
import { LoginRyComponent } from './loginry/loginry.component';
import { LoginTriComponent } from './logintrinitas/logintri.component';
import { LoginFinantahComponent } from './loginfinantah/loginfinantah.component';
import { ResetpwdComponent } from './login/resetpwd.component';
import { ResetpwdBaseComponent } from './loginbase/resetpwdbase.component';
import { ResetpwdryComponent } from './loginry/resetpwdry.component';
import { ResetpwdTriComponent } from './logintrinitas/resetpwdtri.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// bugsnag y rutas
import { environment } from '../environments/environment';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

  Bugsnag.start({ apiKey: '1dea182627ea03e851151f49d6dadbcd', //'6118698d1cf7579b2c82940f401f2c64', 
  autoDetectErrors: true,
  releaseStage: environment.Ambiente,
  enabledReleaseStages: [ 'TestFactoraje', 'ProdFactoraje', 'DevFactoraje' ],
  enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request', 'user'],
  });


export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginBaseComponent,
    LoginRyComponent,
    ResetpwdComponent,
    ResetpwdBaseComponent,
    ResetpwdryComponent,
    LoginTriComponent,
    LoginFinantahComponent,
    ResetpwdTriComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    PagesModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
