import { Component, HostListener, OnInit, Injector  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as XLSX from 'xlsx';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NumberFormatPipe } from '../../pipes/numberFormat.pipe';
import { MonthYearFormatPipe } from '../../pipes/monthYearFormat.pipe';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {this.esmovil(event.target.innerWidth);}
  // animaciones y banderas para los elementos
  groupedData: { [key: string]: { data: any[], visible: boolean } } = {};
  groupedDataCad: { [key: string]: { data: any[], visible: boolean } } = {};
  currentMonth: string;
  animationClass = '';
  animationClassRo1 = '';
  animationClassRo3 = '';
  animationClassRo4 = '';
  animationClassRo5 = '';
  ro1 = false;
  ro2 = false;
  ro3 = false;
  ro4 = false;
  ro5 = false;
  /////
  mobile = false;
  usuarios: string;
  idu = '';
  data: any;
  data2: any;
  data3: any;
  options: any;
  optionsdi: any;
  options2: any;
  adminInvoices: any[] = [];
  adminRequests: any[] = [];
  datos: any[] = [];
  nombrecadena: string;
  totalintereses = '0';
  posemana = '0';
  primerdash = false;
  // SOFOM ////////
  sofomdash = false;
  afiliadostotales: any[] = [];
  dispuestointeres: any[] = [];
  facturasvsdescue: any[] = [];
  facturasvsdesord: any[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  proveedoresafili: any[] = [];
  proveedoresaford: any[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  avgdiscountdays : any[] = [];
  pagosydisperciones: any[] = [];
  ImpTotFacUlTresMeses: any[] = [];
  ImpTotFacNoDescXCad: any[] = []; 
  ImpTotOpxMesACur: any[] = [];
  ImpTotOxFonYRecuProp: any[] = [];
  TopTenProvXImpOpDivXMes: any[] = [];
  TopTenCadXImpOpDivXMes: any[] = [];
  IntOpMes: any[] = [];
  ImpTotCobCadXMes: any[] = [];
  ////////////////
  // COMPANY /////
  companydash = false;
  facturasCanceladas: any[] = [];
  proximosPagos: any[] = [];
  facturasdescontadas: any[] = [];
  facturasdescontadasproveedores: any[] = [];
  facturascanceladastotales: any[] = [];
  comisionPagoSofom: any[] = [];
  porcentajecomision = '';
  isCompany: any[] = [];
  ///////////////
  // SUPLIER ////
  suplierdash = false;
  facturasnodescontadas: any[] = [];
  facturasdescontadassuplier: any[] = [];
  facturasdescontadasmes: any[] = [];
  importefacturas: any[] = [];
  isSuppier: any[] = [];
  ///////////////
  deltab = true;
  totdispu = 0;
  totcob = 0;
  delinquency = false;
  delinquencyResume = [];
  delinquencyResumeOrigin = [];
  delinquencyColsEN = [
    {field: 'customer', name: 'Customer'},
    {field: '1-30', name: '1-30'},
    {field: '31-60', name: '31-60'},
    {field: '46-60', name: '46-60'},
    {field: '61-90', name: '61-90'},
    {field: '+90', name: '+90'},
    {field: 'oldest_inovice_age', name: 'Oldest Invoice Age'},
    {field: 'total', name: 'Total'}
  ];
  delinquencyColsES = [
    {field: 'customer', name: 'Cliente'},
    {field: '1-30', name: '1-30'},
    {field: '31-60', name: '31-60'},
    {field: '46-60', name: '46-60'},
    {field: '61-90', name: '61-90'},
    {field: '+90', name: '+90'},
    {field: 'oldest_inovice_age', name: 'Factura más antigua'},
    {field: 'total', name: 'Total'}
  ];
  delinquencyCols = this.delinquencyColsES;
  customers = [];
  tituloES = 'Facturas con retraso';
  tituloEN = 'Old Invoices';
  titulo = this.tituloES;

  thipousuario: any[] = [];
  fileName = '';
  iw = 1300;
  constructor(public _usuarioservice: UsuarioService,
              public _dashboardservice: DashboardService,
              private route: ActivatedRoute,
              public router: Router,
              private usuarioService: UsuarioService,
              private injector: Injector) {}

  ngOnInit() {
    
    this.optionsdi = {
      responsive: false,
      maintainAspectRatio: false
    };
    this.esmovil(window.innerWidth);
    this.idu = localStorage.getItem('id');
    this.companydash = false;
    this.suplierdash = false;
    this.sofomdash = false;
    this.delinquency = false;
    this.delinquencyResume = [];
    this.delinquencyResumeOrigin = [];
    this.customers = [];
    
    this.usuarioService.getUsuario(this.idu).subscribe(resp => {
      if(resp[0].status == 'PENDIENTE AFILIACION'){
        this.router.navigate(['/usuariosproveedores/afiliacion']);
      }
    })

    this._dashboardservice.getUserType(this.idu).subscribe( resp => { this.thipousuario = resp;
      // DASHBOARD SOFOM /////////////////////////////////////////////////
      if (this.thipousuario[0].tipo_usuario == 'SOFOM') {
        
        const observable1 = this._dashboardservice.getImpTotFacUlTresMeses().pipe(catchError(error => { return of (error); }));
        const observable2 = this._dashboardservice.getImpTotFacNoDescXCad().pipe(catchError(error => { return of (error); }));
        const observable3 = this._dashboardservice.getImpTotOpxMesACur().pipe(catchError(error => { return of (error); }));
        const observable4 = this._dashboardservice.getImpTotOxFonYRecuProp().pipe(catchError(error => { return of (error); }));
        const observable5 = this._dashboardservice.getTopTenProvXImpOpDivXMes().pipe(catchError(error => { return of (error); }));
        const observable6 = this._dashboardservice.getTopTenCadXImpOpDivXMes().pipe(catchError(error => { return of (error); }));
        const observable7 = this._dashboardservice.getIntOpMes().pipe(catchError(error => { return of (error); }));
        const observable8 = this._dashboardservice.getImpTotCobCadXMes().pipe(catchError(error => { return of (error); }));
        const observable9 = this._dashboardservice.getAfiliadosTotales().pipe(catchError(error => { return of (error); }));
        const observable10= this._dashboardservice.getAvgDiscountDays().pipe(catchError(error => { return of (error); }));
        const observable11= this._dashboardservice.getFacturasvsDescuentos().pipe(catchError(error => { return of (error); }));
        const observable12= this._dashboardservice.getProveedoresAfiliados().pipe(catchError(error => { return of (error); }));
        const observable13= this._dashboardservice.delinquencyIndicatorAdmin().pipe(catchError(error => { return of (error); }));
        const observable14= this._dashboardservice.getDispuestoInterescobrado().pipe(catchError(error => { return of (error); }));
        forkJoin([observable1, observable2, observable3, observable4, observable5, observable6, observable7, observable8, observable9,
                  observable10, observable11, observable12, observable13, observable14
        ])
        .subscribe(
          async ([ImpTotFacUlTresMeses, ImpTotFacNoDescXCad, ImpTotOpxMesACur, ImpTotOxFonYRecuProp, TopTenProvXImpOpDivXMes,
          TopTenCadXImpOpDivXMes, IntOpMes, ImpTotCobCadXMes, afiliadostotales, avgdiscountdays, facturasvsdescue, proveedoresafiliados,
          delinquencyIndicatorAdmin, DispuestoInterescobrado]) => {

            if (ImpTotFacUlTresMeses !== undefined && !ImpTotFacUlTresMeses.error) {
              this.ImpTotFacUlTresMeses = ImpTotFacUlTresMeses;
            }
            if (ImpTotFacNoDescXCad !== undefined && !ImpTotFacNoDescXCad.error) {
              this.ImpTotFacNoDescXCad = ImpTotFacNoDescXCad;
            }
            if (ImpTotOpxMesACur !== undefined && !ImpTotOpxMesACur.error) {
              this.ImpTotOpxMesACur = ImpTotOpxMesACur;
            }

            if (ImpTotOxFonYRecuProp !== undefined && !ImpTotOxFonYRecuProp.error) {
              this.ImpTotOxFonYRecuProp = ImpTotOxFonYRecuProp;
            }
            if (TopTenProvXImpOpDivXMes !== undefined && !TopTenProvXImpOpDivXMes.error) {
              this.TopTenProvXImpOpDivXMes = TopTenProvXImpOpDivXMes;
              this.groupDataByMonth();
            }
            if (TopTenCadXImpOpDivXMes !== undefined && !TopTenCadXImpOpDivXMes.error) {
              this.TopTenCadXImpOpDivXMes = TopTenCadXImpOpDivXMes;
              this.groupDataByMonthCad();
            }
            if (IntOpMes !== undefined && !IntOpMes.error) {
              this.IntOpMes = IntOpMes;
            }
            if (ImpTotCobCadXMes !== undefined && !ImpTotCobCadXMes.error) {
              this.ImpTotCobCadXMes = ImpTotCobCadXMes;
            }
            if (afiliadostotales !== undefined && !afiliadostotales.error) {
              this.afiliadostotales = afiliadostotales;
            }
            if (avgdiscountdays !== undefined && !avgdiscountdays.error) {
              this.avgdiscountdays = avgdiscountdays;
            }
            if (facturasvsdescue !== undefined && !facturasvsdescue.error) {
              this.facturasvsdesord = facturasvsdescue.sort((a: { mes: string; }, b: { mes: string; }) => {
              return this.getMonthIndex(a.mes) - this.getMonthIndex(b.mes);
              });
            }
            if (proveedoresafiliados !== undefined && !proveedoresafiliados.error) {
              this.proveedoresaford = proveedoresafiliados.sort((a: { mes: string; }, b: { mes: string; }) => {
              return this.getMonthIndex(a.mes) - this.getMonthIndex(b.mes);
              });
            }
            if (delinquencyIndicatorAdmin !== undefined && !delinquencyIndicatorAdmin.error) {
              if(delinquencyIndicatorAdmin.length > 0){
                delinquencyIndicatorAdmin.forEach((f: { customer: any; }) => {
                  this.delinquencyResume.push(f);
                  let encontrado = this.customers.find(c => c == f.customer);
                  if(encontrado == undefined){
                    this.customers.push(f.customer);
                  }
                });
                this.delinquencyResumeOrigin = this.delinquencyResume;
                this.delinquency = true;
              }
            }
            if (DispuestoInterescobrado !== undefined && !DispuestoInterescobrado.error) {
              this.dispuestointeres = DispuestoInterescobrado;
              this.totdispu = parseFloat(this.dispuestointeres[0].total_dispuesto_enero.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_febrero.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_marzo.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_abril.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_mayo.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_junio.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_julio.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_agosto.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_septiembre.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_octubre.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_noviembre.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_dispuesto_diciembre.replace(/,/g, ''))
              this.totcob = parseFloat(this.dispuestointeres[0].total_intereses_enero.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_febrero.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_marzo.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_abril.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_mayo.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_junio.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_julio.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_agosto.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_septiembre.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_octubre.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_noviembre.replace(/,/g, ''))+
              parseFloat(this.dispuestointeres[0].total_intereses_diciembre.replace(/,/g, ''))
                                                                                 this.data2 = {
             labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
                      'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
             datasets: [
                       {
                           label: 'Total Dispuesto ',
                           backgroundColor: '#949dab',
                           borderColor: '#1E88E5',
                           data: [parseFloat(this.dispuestointeres[0].total_dispuesto_enero.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_febrero.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_marzo.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_abril.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_mayo.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_junio.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_julio.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_agosto.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_septiembre.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_octubre.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_noviembre.replace(/,/g, '')),
                                  parseFloat(this.dispuestointeres[0].total_dispuesto_diciembre.replace(/,/g, ''))
                                  ]
                       },
                       {
                        label: 'Total Cobrado ',
                        backgroundColor: '#434750',
                        borderColor: '#7CB342',
                        data: [parseFloat(this.dispuestointeres[0].total_intereses_enero.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_febrero.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_marzo.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_abril.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_mayo.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_junio.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_julio.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_agosto.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_septiembre.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_octubre.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_noviembre.replace(/,/g, '')),
                               parseFloat(this.dispuestointeres[0].total_intereses_diciembre.replace(/,/g, ''))
                               ]
                    },
                       ]
            };
            }
            
            const errors = [
              ImpTotFacUlTresMeses, ImpTotFacNoDescXCad, ImpTotOpxMesACur, ImpTotOxFonYRecuProp,
              TopTenProvXImpOpDivXMes, TopTenCadXImpOpDivXMes, IntOpMes, ImpTotCobCadXMes,
              afiliadostotales, avgdiscountdays, facturasvsdescue, proveedoresafiliados,
              delinquencyIndicatorAdmin, DispuestoInterescobrado
            ].filter(result => result.error);
      
            if (errors.length > 0) {
              console.error('llamadas con error: ', errors);
            }
            this.sofomdash = true;
            Swal.close();
            setTimeout(() => {
              const ro1Element = document.getElementById('ro1');
              if (ro1Element) {
                ro1Element.classList.remove('hidden');
                ro1Element.classList.add('fadeInUp');
                
              } else {
                console.log('');
              }
            }, 1); 
        });                                                      
      }
      // DASHBOARD COMPANY ////////////////////////////////////////////////
    // tslint:disable-next-line: align
    if (this.thipousuario[0].tipo_usuario == 'COMPANY') {
      this.companydash = true;

      this._dashboardservice.delinquencyIndicator(this.thipousuario[0].company_id).subscribe(resp => {
        
        if(resp.length > 0){
          this.delinquency = true;
          resp.forEach(f => {
            this.delinquencyResume.push(f);
          });
        }
      })

      this._dashboardservice.getFacturasCanceladas(this.thipousuario[0].company_id).subscribe( resp7 => { this.facturasCanceladas = resp7; } );
      this._dashboardservice.getProximosPagos(this.thipousuario[0].company_id).subscribe( resp8 => { this.proximosPagos = resp8; } );
      this._dashboardservice.getFacturasdescontadasynodescontadas(this.thipousuario[0].company_id).subscribe( resp9 => { this.facturasdescontadas = resp9; } );
      this._dashboardservice.getFacturasdescontadasproveedores(this.thipousuario[0].company_id).subscribe( resp10 => { this.facturasdescontadasproveedores = resp10; } );
      this._dashboardservice.getFacturasCanceladasTotales(this.thipousuario[0].company_id).subscribe( resp11 => { this.facturascanceladastotales = resp11;
                                                                                                                  this.data2 = {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
                   'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
                    {
                        label: 'Facturas canceladas ',
                        backgroundColor: '#949dab',
                        borderColor: '#1E88E5',
                        data: [ this.facturascanceladastotales[0].enero,
                        this.facturascanceladastotales[0].febrero,
                        this.facturascanceladastotales[0].marzo,
                        this.facturascanceladastotales[0].abril,
                        this.facturascanceladastotales[0].mayo,
                        this.facturascanceladastotales[0].junio,
                        this.facturascanceladastotales[0].julio,
                        this.facturascanceladastotales[0].agosto,
                        this.facturascanceladastotales[0].septiembre,
                        this.facturascanceladastotales[0].octubre,
                        this.facturascanceladastotales[0].noviembre,
                        this.facturascanceladastotales[0].diciembre,
                               ]
                    }
                    ]
         };
      } );
      this._dashboardservice.getComisionPagoSofom(this.thipousuario[0].company_id).subscribe( resp12 => { this.comisionPagoSofom = resp12; this.porcentajecomision = this.comisionPagoSofom[0].porcentaje_comision;
                                                                                                          this.data3 = {
  labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
  'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  datasets: [
  {
  label: 'Comisión ',
  backgroundColor: '#949dab',
  borderColor: '#1E88E5',
  data: [ parseFloat(this.comisionPagoSofom[0].comision_enero.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_febrero.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_marzo.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_abril.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_mayo.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_junio.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_julio.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_agosto.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_septiembre.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_octubre.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_noviembre.replace(/,/g, '')),
  parseFloat(this.comisionPagoSofom[0].comision_diciembre.replace(/,/g, '')),
  ]
  }
  ]
  };
  } );
      }
      ////////////////////////////////////////////////////////////////////
      // DASHBOARD SUPLIER ////////////////////////////////////////////////
    if (this.thipousuario[0].tipo_usuario == 'SUPPLER') {
      this.suplierdash = true;
      this._dashboardservice.getFacturasNoDescontadas(this.thipousuario[0].supplier_id).subscribe( resp13 => { this.facturasnodescontadas = resp13; } );
      this._dashboardservice.getFacturasDescontadas(this.thipousuario[0].supplier_id).subscribe( resp14 => { this.facturasdescontadassuplier = resp14; } );
      this._dashboardservice.getFacturasDescontadasMes(this.thipousuario[0].supplier_id).subscribe( resp15 => { this.facturasdescontadasmes = resp15; } );
      this._dashboardservice.getImporteFacturas(this.thipousuario[0].supplier_id).subscribe( resp16 => { this.importefacturas = resp16; } );
    }
    ////////////////////////////////////////////////////////////////////
    } );
  }
  //Para la tabla top ten proveedores
  groupDataByMonth() {
    const currentYear = new Date().getFullYear();
    const months = [
    `ENERO ${currentYear}`, `FEBRERO ${currentYear}`, `MARZO ${currentYear}`, `ABRIL ${currentYear}`,
    `MAYO ${currentYear}`, `JUNIO ${currentYear}`, `JULIO ${currentYear}`, `AGOSTO ${currentYear}`,
    `SEPTIEMBRE ${currentYear}`, `OCTUBRE ${currentYear}`, `NOVIEMBRE ${currentYear}`, `DICIEMBRE ${currentYear}`
  ];

    months.forEach(month => {
      const filteredData = this.TopTenProvXImpOpDivXMes.filter(item => item.mes === month);

      // convierto el total a numero y lo ordeno de mayor a menor
      filteredData.sort((a, b) => parseFloat(b.total) - parseFloat(a.total));

      this.groupedData[month] = {
        data: filteredData,
        visible: false
      };
    });
      // aqui sacamos la tabla del mes actual que se pondra a la derecha
    this.currentMonth = this.getCurrentMonth();
    if (this.currentMonth && this.groupedData[this.currentMonth]) {
      this.groupedData[this.currentMonth].visible = true;
    }
  }

  getCurrentMonth(): string {
    const currentDate = new Date();
    const monthNames = [
        "ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
        "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"
    ];
    const month = monthNames[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    return `${month} ${year}`;
}

  toggleVisibility(month: string) {
    this.groupedData[month].visible = !this.groupedData[month].visible;
  }

  getKeys(obj: { [key: string]: { data: any[], visible: boolean } }) {
    return Object.keys(obj);
  }

  getMonthIndex(month: string): number {
    const monthOrder = [
      'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO',
      'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'
    ];
    return monthOrder.indexOf(month);
  }

  //Para la tabla top ten cadenas
  groupDataByMonthCad() {
    const currentYear = new Date().getFullYear();
    const months = [
    `ENERO ${currentYear}`, `FEBRERO ${currentYear}`, `MARZO ${currentYear}`, `ABRIL ${currentYear}`,
    `MAYO ${currentYear}`, `JUNIO ${currentYear}`, `JULIO ${currentYear}`, `AGOSTO ${currentYear}`,
    `SEPTIEMBRE ${currentYear}`, `OCTUBRE ${currentYear}`, `NOVIEMBRE ${currentYear}`, `DICIEMBRE ${currentYear}`
  ];

    months.forEach(month => {
      const filteredData = this.TopTenCadXImpOpDivXMes.filter(item => item.mes === month);

      // Convert 'total' to number and sort by descending order
      filteredData.sort((a, b) => parseFloat(b.total) - parseFloat(a.total));

      this.groupedDataCad[month] = {
        data: filteredData,
        visible: false
      };
    });
    this.currentMonth = this.getCurrentMonthCad();
    if (this.currentMonth && this.groupedDataCad[this.currentMonth]) {
      this.groupedDataCad[this.currentMonth].visible = true;
    }
  }

  getCurrentMonthCad(): string {
    const currentDate = new Date();
    const monthNames = [
        "ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
        "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"
    ];
    const month = monthNames[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    return `${month} ${year}`;
}

  toggleVisibilityCad(month: string) {
    this.groupedDataCad[month].visible = !this.groupedDataCad[month].visible;
  }

  getKeysCad(obj: { [key: string]: { data: any[], visible: boolean } }) {
    return Object.keys(obj);
  }

  esmovil(wid) {
    if(wid < 640) {
      this.mobile = true;
    } else {
    this.mobile = false;
    }
  }
  changeLg(){
    let LgSel = (document.getElementById('selLg') as HTMLInputElement).value;
    if(LgSel == 'ES'){
      this.delinquencyCols = this.delinquencyColsES;
      this.titulo = this.tituloES;
    }else if(LgSel == 'EN'){
      this.delinquencyCols = this.delinquencyColsEN;
      this.titulo = this.tituloEN;
    }
  }

  change(){
    let customerSel = (document.getElementById('selCustomer') as HTMLInputElement).value;
    if(customerSel == ''){
      this.delinquencyResume = this.delinquencyResumeOrigin;
      return;
    }
    this.delinquencyResume = this.delinquencyResumeOrigin.filter(d => d.customer == customerSel);
  }

  facturas_no_descontadas() {
    this.fileName = 'FacturasNoDescontadas.xlsx';
    /* table id is passed over here */
    const element = document.getElementById('facturasnodescontadas');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
 }

 facturas_descontadas() {
  this.fileName = 'FacturasDescontadas.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('facturasdescontadas');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

descuentos_e_intereses_del_mes() {
  this.fileName = 'DescuentoseInteresesDelMes.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('descuentoseinteresesdelmes');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

descuentos_totales() {
  this.fileName = 'DescuentosTotales.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('descuentostotales');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

siguientes_pagos() {
  this.fileName = 'SiguientesPagos.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('siguientespagos');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

porcentaje_de_descuento() {
  this.fileName = 'PorcentajeDeDescuento.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('porcentajededescuento');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

facturas_canceladas() {
  this.fileName = 'FacturasCanceladas.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('facturascanceladas');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

facturas_descontadas_vs_no_descontadas() {
  this.fileName = 'FacturasDescontadasVsNoDescontadas.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('facturasdescontadasvsnodescontadas');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

porcentaje_de_descuento_sofom() {
  this.fileName = 'PorcentajeDeDescuento.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('porcentajededescuentosofom');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

afiliados() {
  this.fileName = 'Afiliados.xlsx';
  /* table id is passed over here */
  const element = document.getElementById('afiliados');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, this.fileName);
}

//funcion para convertir y tratar los datos de las tablas top ten
exportToExcel(subHed: string) {
  /* seleccionamos la data dependiendo de la tabla que vayamos a sacar el excel*/
  let tedata: { [x: string]: { data: any[]; visible: boolean; } | { data: any[]; visible: boolean; }; }
  if (subHed.toLowerCase() == 'proveedor') {
    tedata = this.groupedData;
  } else if (subHed.toLowerCase() == 'cadena' ) {
    tedata = this.groupedDataCad;
  } else {
    return;
  }
  /*se obtiene el año en curso para asignarlo a los meses garantizando que cuando se cambie de año no tener que modificar esto */
  const currentYear = new Date().getFullYear();
  const months = [
    `ENERO ${currentYear}`, `FEBRERO ${currentYear}`, `MARZO ${currentYear}`, `ABRIL ${currentYear}`,
    `MAYO ${currentYear}`, `JUNIO ${currentYear}`, `JULIO ${currentYear}`, `AGOSTO ${currentYear}`,
    `SEPTIEMBRE ${currentYear}`, `OCTUBRE ${currentYear}`, `NOVIEMBRE ${currentYear}`, `DICIEMBRE ${currentYear}`
  ];

  const worksheetData = [];
  const headers = [];
  const subHeaders = [];

  // se agregan los encabezados
  for (const month of months) {
    headers.push(month);
    headers.push(''); // se agrega un encabezado vacio para poder hacer merge
    subHeaders.push(subHed);
    subHeaders.push('Total');
  }
  worksheetData.push(headers);
  worksheetData.push(subHeaders);

  // se calcula el maximo de filas
  let maxRows = 0;
  for (const month of months) {
    const monthDataLength = tedata[month].data.length || 1;
    if (monthDataLength > maxRows) {
      maxRows = monthDataLength;
    }
  }

  // se agregan las filas con el if para dependiendo de que se selecciono agregar unas u otras
  for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
    const row = [];
    for (const month of months) {
      const monthData = tedata[month].data;
      if (monthData.length > rowIndex) {
        if (subHed.toLowerCase() == 'proveedor') {
          row.push(`${monthData[rowIndex].proveedor}`);
          row.push(`${monthData[rowIndex].total}`);
        } else if (subHed.toLowerCase() == 'cadena' ) {
          row.push(`${monthData[rowIndex].cadena}`);
        row.push(`${monthData[rowIndex].total}`);
        }
        
      } else {
        row.push('');
        row.push('');
      }
    }
    worksheetData.push(row);
  }

  // creamos la hoja
  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // se hace el merge de las celdas para los encabezados
  const merges = [];
  for (let i = 0; i < months.length; i++) {
    merges.push({
      s: { r: 0, c: i * 2 },
      e: { r: 0, c: i * 2 + 1 }
    });
  }
  worksheet['!merges'] = merges;

 // aqui definimos los estilos para los encabezados (en proceso, aun no se muestran como quiero)
 const boldCenterStyle = { alignment: { horizontal: 'center' }, font: { bold: true } };

 // se aplican los estilos a los encabezados y sub encabezados
 for (let c = 0; c < headers.length; c++) {
   const headerCell = XLSX.utils.encode_cell({ r: 0, c });
   const subHeaderCell = XLSX.utils.encode_cell({ r: 1, c });
   worksheet[headerCell].s = boldCenterStyle;
   worksheet[subHeaderCell].s = boldCenterStyle;
 }


  // se ajusta el ancho de las columnas
  const colWidths = [];
  for (let i = 0; i < months.length; i++) {
    colWidths.push({ wpx: 200 });
    colWidths.push({ wpx: 200 });
  }
  worksheet['!cols'] = colWidths;

  const workbook: XLSX.WorkBook = { Sheets: { 'Data': worksheet }, SheetNames: ['Data'] };

  // por ultimo escribimos el archivo
  XLSX.writeFile(workbook, 'Data.xlsx');
}

genxls(id: string) {
  this.fileName = id + '.xlsx';

  // obtener instancias de los pipes
  const monthYearFormatPipe = this.injector.get(MonthYearFormatPipe);

  // obtener los datos de la tabla
  const table = document.getElementById(id) as HTMLTableElement;
  const dataArray = [];

  // iteramos sobre las filas de la tabla
  for (let i = 0; i < table.rows.length; i++) {
    const row = table.rows[i];
    const rowData = [];

    // iteramos sobre las celdas de la fila
    for (let j = 0; j < row.cells.length; j++) {
      const cell = row.cells[j];
      let cellValue = cell.innerText.trim();

      // aplicamos pipes a las celdas correspondientes
      if (i !== 0 && j === 0) { 
        cellValue = monthYearFormatPipe.transform(cellValue);
      }

      rowData.push(cellValue);
    }

    dataArray.push(rowData);
  }

  // formateamos las cantidades como números
  for (let r = 1; r < dataArray.length; r++) { // empezar desde 1 para omitir la fila de encabezados
    const row = dataArray[r];
    for (let c = 1; c < row.length; c++) { // empezar desde 1 para omitir la primera columna
      const cellValue = row[c];
      const numericValue = parseFloat(cellValue.replace(/,/g, '')); // convertimos string a numero, eliminando comas
      if (!isNaN(numericValue)) {
        row[c] = numericValue; // reemplazamos el valor de la celda con el valor numérico
      }
    }
  }

  // creamos la hoja 
  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataArray);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // exportamos la hoja a exccel
  XLSX.writeFile(wb, this.fileName);
}

toggleElements(ele: string) {
  let animationClassKey: string;
  let stateKey: string;

  switch (ele) {
    case 'ro1':
      stateKey = 'ro1';
      animationClassKey = 'animationClassRo1';
      break;
    case 'ro2':
      stateKey = 'ro2';
      animationClassKey = 'animationClass';
      break;
    case 'ro3':
      stateKey = 'ro3';
      animationClassKey = 'animationClassRo3';
      break;
    case 'ro4':
      stateKey = 'ro4';
      animationClassKey = 'animationClassRo4';
      break;
    case 'ro5':
      stateKey = 'ro5';
      animationClassKey = 'animationClassRo5';
      break;
    default:
      return;
  }

  if (this[stateKey]) {
    this[animationClassKey] = 'slide-up';
    setTimeout(() => {
      this[stateKey] = !this[stateKey];
      this[animationClassKey] = '';
    }, 500);
  } else {
    this[stateKey] = !this[stateKey];
    this[animationClassKey] = 'slide-down';
  }
}

//para expandir la tabla delinquenci
cch() {
  this.deltab = !this.deltab;
}
cc() {
  if (this.deltab) {
    var x = document.getElementById("siguientespagospp");
    var a = document.getElementsByName("1-30");
    var b = document.getElementsByName("31-60");
    var c = document.getElementsByName("46-60");
    var d = document.getElementsByName("61-90");
    var e = document.getElementsByName("mas90");
    var f = document.getElementsByName("oldest");
    var ths = x.getElementsByTagName("th");
    var thd = x.getElementsByTagName("td");
    ths[1].style.display = 'none'
    ths[2].style.display = 'none'
    ths[3].style.display = 'none'
    ths[4].style.display = 'none'
    ths[5].style.display = 'none'
    ths[6].style.display = 'none'
    a.forEach((el) => {
      el.style.display = 'none'
    })
    b.forEach((el) => {
      el.style.display = 'none'
    })
    c.forEach((el) => {
      el.style.display = 'none'
    })
    d.forEach((el) => {
      el.style.display = 'none'
    })
    e.forEach((el) => {
      el.style.display = 'none'
    })
    f.forEach((el) => {
      el.style.display = 'none'
    })
    this.deltab = false;
  } else {
    var x = document.getElementById("siguientespagospp");
    var a = document.getElementsByName("1-30");
    var b = document.getElementsByName("31-60");
    var c = document.getElementsByName("46-60");
    var d = document.getElementsByName("61-90");
    var e = document.getElementsByName("mas90");
    var f = document.getElementsByName("oldest");
    var ths = x.getElementsByTagName("th");
    var thd = x.getElementsByTagName("td");
    ths[1].style.display = 'inline'
    ths[2].style.display = 'inline'
    ths[3].style.display = 'inline'
    ths[4].style.display = 'inline'
    ths[5].style.display = 'inline'
    ths[6].style.display = 'inline'
    a.forEach((el) => {
      el.style.display = 'block'
    })
    b.forEach((el) => {
      el.style.display = 'block'
    })
    c.forEach((el) => {
      el.style.display = 'block'
    })
    d.forEach((el) => {
      el.style.display = 'block'
    })
    e.forEach((el) => {
      el.style.display = 'block'
    })
    f.forEach((el) => {
      el.style.display = 'block'
    })
    this.deltab = true;
  }
  

}

}
