import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MantContEmit {
  firmantesEmitter = new EventEmitter<{idc: any}>();
  actacEmitter = new EventEmitter<{idc: any}>();
  domiciliosEmitter = new EventEmitter<Object>();
  capafiliacionEmitter = new EventEmitter<Object>();
  caratulaEmitter = new EventEmitter<Object>();
}