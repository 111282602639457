// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// AMBIENTE TEST
// ng serve
// ng build

export const environment = {
  production: false,
  // ambiente
  Ambiente: 'TestFactorajeAU',
  // para mi fiel /////
  APP_ID: '',
  APP_SECRET: '',
  URL_MIFIEL: '',
  // para mi fiel /////
  URL_SERVICIOS: 'https://fgtest.devfactorgfc.com/api/v1',
 // SECRET_KEY: 'ae82033a0fb36d9783c1914086712df7',
  SECRET_KEY: 'a487efef14bed450a9709ca41f2619cb',
  CLIENTE: 'FACTORGFCGLOBAL',
  firebase: {
    apiKey: "AIzaSyA18WqZD2p0I7WMn0-URsF7FG5MdY0bcHQ",
    authDomain: "testfgau-a9c64.firebaseapp.com",
    projectId: "testfgau-a9c64",
    storageBucket: "testfgau-a9c64.appspot.com",
    messagingSenderId: "555627111451",
    appId: "1:555627111451:web:38f0f31ac352bf427904be",
    measurementId: "G-ZD9S8VW5T2"
  },
  AWS: {
    accessKeyId: "AKIAWRCSQFXTXKQ57W5G",
    secretAccessKey: "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",
    region: "us-east-1",
    folder: "factoraje_contributor_documents/TestFGAU/",
    bucketNomina: "elasticbeanstalk-us-east-1-448997109223",
  },
};
