import { Component, Input, OnInit } from '@angular/core';
import { StpService } from '../../../services/stp/stp.service';
import swal2 from 'sweetalert2';
import { Router } from '@angular/router';
import { ReportesService } from '../../../services/reportes/reportes.service';
import { ParametrosGeneralesService } from '../../../services/parametrosgenerales/parametrosgenerales.service';

@Component({
  selector: 'app-dispersion-stp',
  templateUrl: './dispersion-stp.component.html',
  styles: []
})
export class DispersionStpComponent implements OnInit {

  constructor(private _stpservice: StpService,
              private _reportesservice: ReportesService,
              private parametrosService: ParametrosGeneralesService) { }

  saldo = 0;
  token = localStorage.getItem('token');
  //doc = new jsPDF();
  facturas: any[] = [];
  usuario: string;
  cols: any[];
  colspdf: any[];
  selectedFac: any[];
  router: Router;
  fileName = 'ListaDeFacturas.xlsx';
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];
  selectedInvoices = [];

  nombre_ordenante = '';
  tipo_cuenta_ordenante = '';
  cuenta_ordenante = '';

  _selectedColumns: any[];

  ngOnInit() {
    this.cols = [
      
      //  { field:  'id_factura', header: 'ID'},
      //   { field:  'id_factura', header: 'ID Factura'},
      //   { field:  'oper', header: 'Operacion'},
      //   { field:  'clave_id', header: 'Clave'},
      //   { field:  'cuenta_origen', header: 'Cuenta Origen'},
      //   { field:  'cuenta_destino', header: 'Cuenta Destino'},
      //   { field:  'importe', header: 'Importe'},
      //   { field:  'referencia', header: 'Referencia'},
      //   { field:  'descripcion', header: 'Descripcion'},
      //   { field:  'rfc_ordenante', header: 'RFC Ordenante'},
      //   { field:  'iva', header: 'IVA'},
      //   { field:  'fecha_aplicacion', header: 'Fecha Aplicacion'},
      //   { field:  'instruccion_pago', header: 'Instruccion Pago'}
      { field:  'institucion_contraparte', header: 'Institucion Contraparte'},
      { field:  'clave_rastreo', header: 'Clave de Rastreo'},
      { field:  'nombre_beneficiario', header: 'Nombre de Beneficiario'},
      { field:  'rfc_curp_beneficiario', header: 'Rfc Curp Beneficiario'},
      { field:  'tipo_pago', header: 'Tipo de Pago'},
      { field:  'tipo_cuenta_beneficiario', header: 'Tipo Cuenta Beneficiario'},
      { field:  'monto', header: 'Monto'},
      { field:  'cuenta_beneficiario', header: 'Cuenta Beneficiario'},
      { field:  'concepto_pago', header: 'Concepto de Pago'},
      { field:  'referencia_numerica', header: 'Referencia Numerica'},
      { field:  'institucion_operante', header: 'Institucion Operante'},
      { field:  'empresa', header: 'Empresa'}
      
    ];
    
    this._selectedColumns = this.cols;
    this.colspdf = [
      //  { field:  'id_factura', header: 'ID'},
      // { field:  'id_factura', header: 'ID Factura'},
      // { field:  'oper', header: 'Operacion'},
      // { field:  'clave_id', header: 'Clave'},
      // { field:  'cuenta_origen', header: 'Cuenta Origen'},
      // { field:  'cuenta_destino', header: 'Cuenta Destino'},
      // { field:  'importe', header: 'Importe'},
      // { field:  'referencia', header: 'Referencia'},
      // { field:  'descripcion', header: 'Descripcion'},
      // { field:  'rfc_ordenante', header: 'RFC Ordenante'},
      // { field:  'iva', header: 'IVA'},
      // { field:  'fecha_aplicacion', header: 'Fecha Aplicacion'},
      // { field:  'instruccion_pago', header: 'Instruccion Pago'}
      { field:  'institucion_contraparte', header: 'Institucion Contraparte'},
      { field:  'clave_rastreo', header: 'Clave de Rastreo'},
      { field:  'nombre_beneficiario', header: 'Nombre de Beneficiario'},
      { field:  'rfc_curp_beneficiario', header: 'Rfc Curp Beneficiario'},
      { field:  'tipo_pago', header: 'Tipo de Pago'},
      { field:  'tipo_cuenta_beneficiario', header: 'Tipo Cuenta Beneficiario'},
      { field:  'monto', header: 'Monto'},
      { field:  'cuenta_beneficiario', header: 'Cuenta Beneficiario'},
      { field:  'concepto_pago', header: 'Concepto de Pago'},
      { field:  'referencia_numerica', header: 'Referencia Numerica'},
      { field:  'institucion_operante', header: 'Institucion Operante'},
      { field:  'empresa', header: 'Empresa'}
    ];
    this.selectedColumnsp = this.cols;
    this.exportColumns = this.colspdf.map(col => ({title: col.header, dataKey: col.field}));
    
    this.parametrosService.getGeneralParam('NOMBRE_ORDENANTE').subscribe(resp => {
      this.nombre_ordenante = resp['value'];
      this.parametrosService.getGeneralParam('CUENTA_ORIGEN_STP').subscribe(resp => {
        this.cuenta_ordenante = resp['value'];
        this.parametrosService.getGeneralParam('TIPO_CUENTA_ORDENANTE').subscribe(resp => {
          this.tipo_cuenta_ordenante = resp['value'];
          this.consulta_saldo_stp();
        })
      })
    })
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  generarReporte() {

    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
  });
    swal2.showLoading();
    this.consulta_saldo_stp();
    const d = new Date((document.getElementById('fechaconsulta')as HTMLInputElement).value);
    d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
  
    if (month.length < 2) {
          month = '0' + month;
      }
    if (day.length < 2) {
          day = '0' + day;
      }
  
    const fecharepor = [year, month, day].join('-');
    const type = ((document.getElementById('type')as HTMLInputElement).value);
  
    this._reportesservice.getStp(fecharepor,type).subscribe(resp => {this.facturas = resp;
                                                                    swal2.close();
                                                                    if ( this.facturas.length === 0 ) {
  
                                                                          swal2.fire(
                                                                            'No se encontraron datos con la fecha:',
                                                                            fecharepor,
                                                                            'error'
                                                                            );
                                                                        } else {
                                                                          // tslint:disable-next-line: forin
                                                                          for (const prop in this.facturas) {
                                                                            this.facturas[prop].importe = this.facturas[prop].monto.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                                                          }
                                                                        }
    }, (err) => {
      swal2.close();
      console.log(err);
      swal2.fire(
           'Error al Consultar los Datos',
           '',
           'error'
        );
      this.ngOnInit();
     } );
  
  
  }

  consulta_saldo_stp(){
    const params = {
      empresa: this.nombre_ordenante,
      cuentaOrdenante: this.cuenta_ordenante
    };
    this._stpservice.getStpAccountBalanceStp(params)
    .subscribe(resp => {
      try{
        this.saldo = resp['response']['respuesta']['saldo'];
        swal2.close();
      }catch{
        swal2.fire('Atención', 'Hubo un problema en la consulta de saldo', 'info');
        return
      }
      
    } , err => {
      console.log(err)
      swal2.fire({title: 'Ocurrio un error al cargar la información', allowOutsideClick: false })} );
  }

  dispersar(){
    console.log('selected', this.selectedInvoices);
    let ordenPago = {};
    let correcto = [];
    let error = [];
    this.selectedInvoices.forEach(i => {
      ordenPago['counterpart_institution'] = i.institucion_contraparte,
      ordenPago['business'] = i.empresa,
      // ordenPago['fechaOperacion'] = '',
      // ordenPago['operation_date'] = '',
      // ordenPago['folioOrigen'] = '',
      // ordenPago['claveRastreo'] = '',
      ordenPago['tracking_code'] = '',
      ordenPago['operating_institution'] = i.institucion_operante,
      ordenPago['amount'] = i.monto,
      ordenPago['payment_type'] = i.tipo_pago,
      ordenPago['ordering_account_type'] = this.tipo_cuenta_ordenante,
      ordenPago['ordering_name'] = this.nombre_ordenante,
      ordenPago['ordering_account'] = this.cuenta_ordenante,
      ordenPago['ordering_rfc_curp'] = 'ND',
      ordenPago['beneficiary_account_type'] = i.tipo_cuenta_beneficiario,
      ordenPago['beneficiary_name'] = i.nombre_beneficiario,
      ordenPago['beneficiary_account'] = i.cuenta_beneficiario,
      ordenPago['beneficiary_rfc_curp'] = i.rfc_curp_beneficiario,
      ordenPago['payment_concept'] = i.concepto_pago,
      // ordenPago['conceptoPago2'] = '',
      // ordenPago['claveCatUsuario1'] = '',
      // ordenPago['claveCatUsuario2'] = '',
      // ordenPago['clavePago'] = '',
      ordenPago['numeric_reference'] = i.referencia_numerica,
      // ordenPago['tipoOperacion'] = '',
      // ordenPago['topologia'] = '',
      // ordenPago['usuario'] = '',
      // ordenPago['medioEntrega'] = '',
      // ordenPago['prioridad'] = '',
      ordenPago['iva'] = ''
    });
    let params = {};
    params['ordenPago'] = ordenPago;

    this._stpservice.registraOrdenPago(params).subscribe(resp => {
      console.log('correcto', params, resp);
      correcto.push(params);
    }, err => {
      console.log('error', params, err);
      error.push(params);
    });

    this.consulta_saldo_stp();
    console.log('resultado', correcto, error);

    swal2.fire('Terminado', 'Finalizó la dispersión, correctos: ' + correcto.length + ', errores: ' + error.length, 'success');
  }

}
