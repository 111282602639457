import { Component, OnInit } from '@angular/core';
import { FacturasService } from '../../services/facturas/facturas.service';
import { ReportesService } from '../../services/reportes/reportes.service';
import swal2 from 'sweetalert2';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-revision-facturas',
  templateUrl: './revision-facturas.component.html',
  styleUrls: ['./revision-facturas.component.css']
})
export class RevisionFacturasComponent implements OnInit {

  facturas = [];
  cargado = false;
  cols = [
    { field: 'folio_factura', header: 'Número de Factura' },
    { field: 'rfc_receptor', header: 'RFC Receptor' },
    { field: 'receptor', header: 'Receptor' },//----------------------
    { field: 'total_factura', header: 'Total' },
    { field: 'fecha_emision', header: 'Fecha de Factura' },
    { field: 'fecha_vencimiento', header: 'Fecha de pago' },

    { field: 'pdf', header: 'PDF' },
  ];
  reporte = [];
  companyReview = [];
  aceptadas = [];
  rechazadas = [];
  listo = false;

  constructor(private facturasService: FacturasService,
              private reportesService: ReportesService) { }

  ngOnInit() {
    this.facturas = [];
    this.reporte = [];
    this.aceptadas = [];
    this.rechazadas = [];
    this.listo = false;

    this.facturasService.getCompanyForReview().subscribe(resp => {
      this.companyReview = resp;
    });

  }

  selCompany(){
    this.aceptadas = [];
    this.rechazadas = [];
    this.listo = true;
    let company = (document.getElementById('company') as HTMLInputElement).value;
    this.facturasService.getReviewInvoices(company).subscribe(resp => {
      // this.facturas = resp;
      this.facturas = [];
      resp.forEach(f => {
        var id = f.id;
        if(this.facturas.find(f => f.id == id) == undefined){
          this.facturas.push(f);
        }
      });
      this.cargado = true;
    })
  }

  almacenarAceptada(rowData){
    this.aceptadas.push(rowData);
    let i = this.facturas.findIndex(f => f.id == rowData.id);
    this.facturas.splice(i, 1);
    if(this.facturas.length == 0){
      this.procesar();
    }
  }

  almacenarRechazada(rowData){
    Swal.fire({
      title: 'Describe la razón del rechazo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if(result.value && result.value != ''){
        rowData['nota_rechazo'] = result.value;
        this.rechazadas.push(rowData);
        let i = this.facturas.findIndex(f => f.id == rowData.id);
        this.facturas.splice(i, 1);
        if(this.facturas.length == 0){
          this.procesar();
        }
      }else{
        swal2.fire('Error', 'Debe teclear una razón válida', 'info');
      }
    })
  }

  procesar(){
    this.aceptadas.forEach(a => {
      this.aceptar(a);
    });
    
    
    this.rechazar();

    this.ngOnInit();
  }

  aceptar(rowData){
    console.log('aceptada', rowData);
    this.facturasService.updateInvoices(rowData.id, {invoice: {status: 'PENDIENTE'}}).subscribe(res => {
      swal2.fire('Éxito', 'Factura aceptada correctamente', 'info');
      this.cargado = false;
    }, err => {
      console.log(err);
      swal2.fire('Atención', err.error.errors[0], 'info');
    });
  }

  rechazar(){
    let params = {};
    let invoices = [];
    if(this.rechazadas.length == 0){
      return;
    }
    this.rechazadas.forEach(r => {
      invoices.push({id: r.id, note: r.nota_rechazo})
    });
    params['invoices'] = invoices;
    params['company_id'] = (document.getElementById('company') as HTMLInputElement).value;
    this.facturasService.rejectInvoices(params).subscribe(resp => {
      swal2.fire('Éxito', 'Facturas rechazadas correctamente', 'info');
    }, err => {
      console.log(err);
      swal2.fire('Error', 'Contacte al administrador', 'info');
    });


  }
  

}
