import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import {NumberFormatPipe} from './numberFormat.pipe';
import {MonthYearFormatPipe} from './monthYearFormat.pipe'; 


@NgModule({
  imports: [
  ],
  declarations: [
    ImagenPipe,
    NumberFormatPipe,
    MonthYearFormatPipe
  ],
  exports: [
    ImagenPipe,
    NumberFormatPipe,
    MonthYearFormatPipe
  ],
  providers: [
    NumberFormatPipe,
    MonthYearFormatPipe,
  ],
})
export class PipesModule { }
