import { Component, OnInit, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ContribuyentesService, OptionsService, MantenimientoContribuyentesService, MantContEmit } from '../../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Subscription } from 'rxjs';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-domicilios',
  templateUrl: './domicilios.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class DomiciliosComponent implements OnInit, OnDestroy {
  @Output() cambioDetectado = new EventEmitter<any>();
  message: string;
  direcciones: any[] = [];
  direccion: any[] = [];
  nombreestado = '';
  nombremunicipio = '';
  tipodomicilio = '';
  calle = '';
  numeroexterior = '';
  numerointerior = '';
  tipoasentamiento = '';
  codigopostal = '';
  neighborhood = '';
  referencia = '';
  muestradirecciones = false;
  estados: any[] = [];
  municipios: any[] = [];
  addresstype: any[] = [];
  suburbtype: any[] = [];
  suburb: any[] = [];
  suburbM: any[] = [];
  contribuyenteslist: any[] = [];
  idestado = '';
  ide = '';
  idm = '';
  idc: string;
  manual = false;
  manualp = false;
  ndd = false;
  private domiciliosSub: Subscription;
  selectedDireccion: string = 'novalor';
  constructor(
               public _contribuyentesService: ContribuyentesService,
               public _optionsservice: OptionsService,
               public _mantenimientocontservice: MantenimientoContribuyentesService,
               public router: Router,
               public http: HttpClient,
               private eventService: MantContEmit  ) { }

  ngOnInit() {
    this.domiciliosSub = this.eventService.domiciliosEmitter.subscribe((data: {idc: number}) => {
      //console.log('data recibida domicilios: ', data);
      this.handleDomiciliosEvent(data);
    });
  }

  ngOnDestroy(): void { 
    if (this.domiciliosSub) {
      this.domiciliosSub.unsubscribe();
      //console.log('DESTRUYO COMPONENTE DOMICILIOS');
    }
    

}

handleDomiciliosEvent(data: {idc: any}) {
      swal2.showLoading();
      this.direcciones = [];
      this.muestradirecciones = false;
      this.idc = data.idc;
      this.ndd = false;
      const observable1 = this._contribuyentesService.getStates();
      const observable2 = this._contribuyentesService.getAdresstype();
      const observable3 = this._contribuyentesService.getAsentamientotype();
      const observable4 = this._mantenimientocontservice.getDirecciones(this.idc);
        forkJoin([observable1, observable2, observable3, observable4]).subscribe(async ([estados, addrestype, suburbtype, direcciones]) => {
          this.estados = estados;
          this.addresstype = addrestype;
          this.suburbtype = suburbtype;
          for (const itemdir of direcciones) {
            await this.filtramunicipiosn(itemdir);
            await this.buscacpn(itemdir);
          }
          this.direcciones = direcciones;
          swal2.close();
              }, (err) => {
                console.log(err);
                swal2.close();
                swal2.fire({
                  title: 'Ocurrio un error al consultar la información',
                  icon: 'info',
                  allowOutsideClick: false
                }). then ( res => {
                  if ( res.value ) {
                    this.router.navigate(['/dashboard']);
                  }
                } );
        });
}

nd() {
  this.ndd = !this.ndd;
}

filtramunicipiosn(itemdir): Promise<void> {
  
  return new Promise((resolve, reject) => {
    this._contribuyentesService.getMunicipios(itemdir.state_id).subscribe(
      data => {
        itemdir.municipios = data;
        if (!data.some(m => m.id === itemdir.municipality_id)) {
          itemdir.municipality_id = null;
        }
        resolve();
      },
      error => reject(error)
    );
  });
}

buscacpn(itemdir): Promise<void> {
  const cp = itemdir.postal_code;
  return new Promise((resolve, reject) => {
  
  this._contribuyentesService.getColoniasxcp( cp ).subscribe(
    resp => {
    itemdir.suburbs = resp;
    itemdir.manual = resp.length === 0;
    resolve();
  }, error => reject(error))
  });
}

modificardireccionn(itemdir) {
  const params = {

    municipality_id: itemdir.municipality_id,
    state_id: itemdir.state_id,
    address_type: itemdir.address_type,
    street: itemdir.street,
    external_number: itemdir.external_number,
    apartment_number: itemdir.apartment_number,
    suburb_type: itemdir.suburb_type,
    suburb: itemdir.suburb,
    postal_code: itemdir.postal_code,
    address_reference: itemdir.address_reference,
    token: '',
    secret_key: ''

  };
  this._mantenimientocontservice.modificaDireccion(itemdir.contributor_id, itemdir.id , params).subscribe( resp => { swal2.close();
    swal2.fire({
title: 'Los datos se modificaron',
text: 'Con exito',
icon: 'success',
showConfirmButton: true,
showCancelButton: false,
allowOutsideClick: false
}). then ( res => {

if ( res.value ) {
  this.handleDomiciliosEvent( {idc: itemdir.contributor_id});
}

} );

}, (err) => {
swal2.close();
console.log(err);
swal2.fire({
title: 'Ocurrio un error',
text: '',
icon: 'error',
showConfirmButton: true,
showCancelButton: false,
allowOutsideClick: false
}). then ( res => {
if ( res.value ) {
  this.handleDomiciliosEvent( {idc: itemdir.contributor_id});
}
} );
});
}

filtramunicipiosp() {

  const estado: any = document.getElementById('estadop');

  const valorestado = estado.options[estado.selectedIndex].value;

  for (const prop in this.estados) {
    if ( this.estados[prop].name === valorestado ) {
      this.idestado = this.estados[prop].id;
      break;
    }
  }

  this._contribuyentesService.getMunicipios( this.idestado ).subscribe(resp => {this.municipios = resp; });

}

buscacpp() {

  const cp = (document.getElementById('postal_codep') as HTMLInputElement).value;

  this._contribuyentesService.getColoniasxcp( cp ).subscribe( resp => { 
    this.suburb = resp; 
    if(this.suburb.length == 0){
      this.manualp = true;
    } else {
      this.manualp = false;
    }
  } );

}

registrardireccionp() {
  swal2.showLoading();
  const estado: any = document.getElementById('estadop');
  const municipio: any = document.getElementById('municipiop');
  const adresstype: any = document.getElementById('adress_typep');
  const suburbtype: any = document.getElementById('suburb_typep');
  
  const valorestado = estado.options[estado.selectedIndex].value;
  const valormunicipio = municipio.options[municipio.selectedIndex].value;
  const valoradresstype = adresstype.options[adresstype.selectedIndex].value;
  const valorsuburbtype = suburbtype.options[suburbtype.selectedIndex].value;
  let valorsuburb;
  if(!this.manualp){
    const suburbb: any = document.getElementById('suburbp');
    valorsuburb = suburbb.options[suburbb.selectedIndex].value;
  }else{
    valorsuburb = (document.getElementById('suburbpM') as HTMLInputElement).value;
  }

  for (const prop in this.estados) {
    if ( this.estados[prop].name === valorestado ) {
      this.ide = this.estados[prop].id;
      break;
    }
  }

  for (const prop in this.municipios) {
    if ( this.municipios[prop].name === valormunicipio ) {
      this.idm = this.municipios[prop].id;
      break;
    }
  }

  const params = {

    municipality_id: this.idm,
    state_id: this.ide,
    address_type: valoradresstype,
    street: (document.getElementById('streetp')as HTMLInputElement).value,
    external_number: (document.getElementById('external_numberp')as HTMLInputElement).value,
    apartment_number: (document.getElementById('apartment_numberp')as HTMLInputElement).value,
    suburb_type: valorsuburbtype,
    suburb: valorsuburb,
    postal_code: (document.getElementById('postal_codep')as HTMLInputElement).value,
    address_reference: (document.getElementById('address_referencep')as HTMLInputElement).value

  };

  this._contribuyentesService.creaDireccionxContribuyente(this.idc, params).subscribe( resp => {
    swal2.close();
    swal2.fire({
      title: 'Los datos se guardaron',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
      }). then ( res => {
      if ( res.value ) {
        this.handleDomiciliosEvent({idc: this.idc});
        this.cambioDetectado.emit();
      }
      } );
      }, (err) => {
        swal2.close();
        console.log(err);
        swal2.fire({
          title: 'Ocurrio un error',
          text: '',
          icon: 'error',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }). then ( res => {
          if ( res.value ) {
            this.handleDomiciliosEvent({idc: this.idc})
          }
        } );
       });

}

borraDireccion(itemdir) {
  swal2.fire({
    title: 'Desea elminiar la direccion seleccionada',
    text: '?',
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }). then ( resp => {
    if ( resp.value) {

      this._mantenimientocontservice.borraDireccion( itemdir.contributor_id, itemdir.id ).subscribe( () => {

        swal2.fire({
          title: 'La direccion se elimino',
          text: 'Con exito',
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
          }). then ( res => {
          if ( res.value ) {
            this.handleDomiciliosEvent({idc: itemdir.contributor_id});
            this.cambioDetectado.emit();
          }
          } );
          }, (err) => {
            console.log(err);
            swal2.fire({
              title: 'Ocurrio un error',
              text: '',
              icon: 'error',
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false
            }). then ( res => {
              if ( res.value ) {
                this.handleDomiciliosEvent({idc: itemdir.contributor_id});
              }
            } );
           });

    }
  });
}


}