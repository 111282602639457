import { Component, EventEmitter, OnInit } from '@angular/core';
import { UsuarioService, PrivilegiosUsuariosService } from '../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';


@Component({
  selector: 'app-usuariosoptions',
  templateUrl: './usuariosoptions.component.html',
  styleUrls: ['./useroptions.component.css']
})
export class UsuariosOptionsComponent implements OnInit {
  opusuarioEmitter = new EventEmitter<Object>();
  items: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  searchTerm: string = '';
  showac = false;
  edopcusu = true;
  // Variable para mantener el índice de la fila seleccionada
  filaSeleccionada: number | null = null;
  mus = true;
  constructor( public _usuarioservice: UsuarioService,
               public _privilegiosusuarios: PrivilegiosUsuariosService,
               public http: HttpClient) { }
  usuarios: any[] = [];
  cols: any[];
  selectedFac: any[];
  router: Router;

  ngOnInit() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
 });
    swal2.showLoading();
    this._usuarioservice.getUsuarios().subscribe(resp => {this.usuarios = resp; swal2.close();
      this.items = resp;
     }
    , (err) => {swal2.fire({title: 'Ocurrio un error al cargar la información', allowOutsideClick: false })} );

    this.cols = [

      { field: 'id', header: 'ID' },
      { field: 'name', header: 'Nombre' },
      { field: 'email', header: 'Correo' },
      { field: 'privilegios', header: 'Opciones' }

  ];

  }

  ngOnDestroy(): void { 
    this.opusuarioEmitter.unsubscribe();

} 

  opusu(contid: any, nomcont: any) {
    this.showac = true;
    this.edopcusu = false;
    setTimeout(() => {
      this.opusuarioEmitter.emit({contid: contid, nomcont: nomcont});
    }, 100);   
  }

  pru() {
    this.edopcusu = !this.edopcusu;
  }

  pgone() {
    this.currentPage = 1;
    this.filaSeleccionada = -1;
  }

  seleccionarFila(index: number, currentPage: number, itemsPerPage: number) {
    this.filaSeleccionada = index + (currentPage - 1) * itemsPerPage;
  }

  get filteredData(): any[] {

    return this.items.filter(item =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.email.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  rgm(even) {
   // console.log(even)
    this.mus = even.clk;
    }


}
