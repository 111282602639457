import { Component, OnInit } from '@angular/core';
import { ReportesService } from 'src/app/services/service.index';

@Component({
  selector: 'app-seguimiento-pendientes',
  templateUrl: './seguimiento-pendientes.component.html',
  styles: [
  `
    .selected {
      background-color: #EAEBEF;
    }
  `
  ]
})
export class SeguimientoPendientesComponent implements OnInit {

  cols = [
    {field: 'id_factura', header: 'Id Factura'},
    {field: 'folio_factura', header: 'Folio Factura'},
    {field: 'folio_solicitud', header: 'Folio Solicitud'},
    {field: 'folio_solicitud_fondeo', header: 'Folio Solicitud de Fondeo'},
    {field: 'uuid_factura_descontada', header: 'UUID Factura Descontada'},
    {field: 'emisor', header: 'Emisor'},
    {field: 'receptor', header: 'Receptor'},
    {field: 'moneda', header: 'Moneda'},
    {field: 'fecha_operacion', header: 'Fecha Operación'},
    {field: 'fecha_vencimiento', header: 'Fecha de vencimiento'},
    {field: 'estatus', header: 'Estatus'},
    {field: 'total_factura', header: 'Total Factura'},
  ]

  p_proveedor = [];
  p_proveedor_p_cadena_registrado = [];
  p_cadena = [];
  fondeo = [];
  p_fondeo = [];
  filtro = 1;


  constructor(private reportesService: ReportesService) { }

  ngOnInit() {
    this.reportesService.getPendientes().subscribe(resp => {
      this.p_proveedor = resp.filter(p => p.tipo_pendiente == 'REGISTRO DE PAGO PROVEEDOR');
      this.p_proveedor_p_cadena_registrado = resp.filter(p => p.tipo_pendiente == 'REGISTRO DE PAGO PROVEEDOR PAGO CADENA REGISTRADO');
      this.p_cadena = resp.filter(p => p.tipo_pendiente == 'REGISTRO DE PAGO CADENA');
      this.fondeo = resp.filter(p => p.tipo_pendiente == 'REGISTRO DE FONDEO');
      this.p_fondeo = resp.filter(p => p.tipo_pendiente == 'REGISTRO DE PAGO DE FONDEO');
    })
  }

}
