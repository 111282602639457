import { Component, OnInit, Input, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ContribuyentesService, MantContEmit } from '../../../services/service.index';
import { AwsService } from 'src/app/services/awsser/aws.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-caratula',
  templateUrl: './caratula.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class CaratulaComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  @Output() childEvent = new EventEmitter();
  idc;
  contributor_id = '';
  regUsuario = {
    cat: '',
    term: '',
    payment_due_date: '',
    cutoff_date: '',
    opening_commission: '',
    reca: '',
    reca_date: '',
    anticipate_payment_commission: '',
    tiie: '',
    credit_line_amount: '',
    payment_total: '',
    pesos_usd_billing: '',
    credit_line_amount_usd: '',
    payment_total_usd: '',
    sofr: '',
    contributor_id: '',
    id: '',
    legal_personality: ''
   };
  constructor( private eventService: MantContEmit,
               private _contribuyentesService: ContribuyentesService,
               public router: Router,
               public http: HttpClient ) { }

  ngOnInit() {
    this.subscription = this.eventService.caratulaEmitter.subscribe((data: {idc: number}) => {
      //console.log('data recibida: ', data);
      this.handleCaratulaEvent(data);
    });
  }

  ngOnDestroy(): void { 
    this.subscription.unsubscribe();
    //console.log('DESTRUYO CARATULA');
  }

  handleCaratulaEvent(data: {idc: any}) {
    this.idc = data.idc;
    this._contribuyentesService.getContributorDetails(data.idc).subscribe(r => {
      this.regUsuario.cat = r.data[0].cat,
      this.regUsuario.term = r.data[0].term,
      this.regUsuario.payment_due_date = r.data[0].payment_due_date,
      this.regUsuario.cutoff_date = r.data[0].cutoff_date,
      this.regUsuario.opening_commission = r.data[0].opening_commission,
      this.regUsuario.reca = r.data[0].reca,
      this.regUsuario.reca_date = r.data[0].reca_date,
      this.regUsuario.anticipate_payment_commission = r.data[0].anticipate_payment_commission,
      this.regUsuario.tiie = r.data[0].tiie,
      this.regUsuario.credit_line_amount = r.data[0].credit_line_amount,
      this.regUsuario.payment_total = r.data[0].payment_total,
      this.regUsuario.pesos_usd_billing = r.data[0].pesos_usd_billing,
      this.regUsuario.credit_line_amount_usd = r.data[0].credit_line_amount_usd,
      this.regUsuario.payment_total_usd = r.data[0].payment_total_usd,
      this.regUsuario.sofr = r.data[0].sofr,
      this.regUsuario.contributor_id = r.data[0].contributor_id,
      this.regUsuario.id = r.data[0].id,
      this.regUsuario.legal_personality = r.data[0].legal_personality
    }, (err) => {
      if (err.error && err.error.error && err.error.error.includes('contributor_details')) {
        swal2.fire({
          title: 'Atencion',
          text: 'No se puede dar de alta la caratula ya que no se ah terminado el registro',
          icon: 'info',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }). then ( resp => {
          if ( resp.value) {
            console.log('')
          }
        });
      } else {
        // La cadena 'contributor_details' no está presente en err.error.error
        console.log("La cadena 'contributor_details' no está presente.");
      }
    })
    
  }

  //para la caratula
patchUsr(){
  swal2.showLoading();
  let params = {};
  params['contributor_type'] = this.regUsuario.legal_personality;
  params['contributor_details'] = this.regUsuario;
  this._contribuyentesService.patchContributorDetails(params, this.regUsuario.id).subscribe(respo => {
    swal2.fire({
      title: 'Exito',
      text: 'Caratula guardada correctamente',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( resp => {
      if ( resp.value) {
        let ru = this.validarDatos(respo);
        if (ru) {                 
          this.emitirEvento(respo.data.attributes.contributor_id);
        }
      }
    });
  }, err => {
    swal2.fire('Atención', 'Algo salió mal', 'info');
    console.log('error', err);
  })
}

validarDatos(r) {
  // Campos específicos que deseas evaluar
  const fieldsToCheck = [
      'cat',
      'term',
      'payment_due_date',
      'cutoff_date',
      'opening_commission',
      'reca',
      'reca_date',
      'anticipate_payment_commission',
      'tiie',
      'credit_line_amount',
      'payment_total'
  ];

  // Verificar si todos los campos específicos son diferentes de 'N/A', undefined, null, o ''
  let isValid = true;
  let encontradoInvalido = false;
  fieldsToCheck.forEach(field => {
    if (encontradoInvalido) return;
      const value = r.data.attributes[field];
      if (value === 'N/A' || value === undefined || value === null || value === '') {
          isValid = false;
          encontradoInvalido = true; // 
      }
  });

  return isValid;
}
// este evento emite al padre capafiliacion solo cuando todos los campos de la caratula estan completos
emitirEvento(idc) {
  this.childEvent.emit({clk: idc, check: 'vdcar'});
}

}