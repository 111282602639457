import { Component, EventEmitter, OnInit } from '@angular/core';
import { AltaSolicitudesService, ContribuyentesService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-mantenimiento-afiliacion',
  templateUrl: './mantenimiento-afiliacion.component.html',
  styleUrls: ['./mantenimiento-afiliacion.component.css']
})
export class MantenimientoAfiliacionComponent implements OnInit {

  editarContribuyenteEmitter = new EventEmitter<Object>();
  currentPage: number = 1;
  itemsPerPage: number = 10;
  filaSeleccionada: number | null = null;
  searchTerm: string = '';
  items: any[] = [];
  cols = [];
  editarContribuyente = false;

  constructor(private contribuyentesService: ContribuyentesService,
              private solicitudesService: AltaSolicitudesService) { }

  ngOnInit() {
    this.contribuyentesService.getFactoringContributors().subscribe(resp => {
      var contributors = resp['contributors'];
      Object.keys(contributors[0]).forEach(k => {
        this.cols.push({field: k, header: k.charAt(0).toUpperCase() + k.slice(1)})
      })
      this.solicitudesService.getGeneralParam('FACTORAJE_TYPE').subscribe(r => {
        var factoraje_type = r['value'];
        switch(factoraje_type){
          case 'SIN_RECURSO':
            this.items = contributors.filter(c => c.tipo == 'SUPPLIER');
            break;
          case 'CON_RECURSO':
            this.items = contributors.filter(c => c.tipo == 'COMPANY');
            break;
          default:
            swal2.fire('Atención', 'Se debe configurar el parámetro general FACTORAJE_TYPE', 'info');
            break;
        }
        
      });
    });
  }

  ngOnDestroy(): void { 
    this.editarContribuyenteEmitter.unsubscribe();
  } 

  pgone() {
    this.currentPage = 1;
    this.filaSeleccionada = -1;
  }

  get filteredData(): any[] {
    return this.items;

    /* return this.items.filter(item =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.email.toLowerCase().includes(this.searchTerm.toLowerCase())
    ); */
  }

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
  }

  seleccionarFila(index: number, currentPage: number, itemsPerPage: number) {
    this.filaSeleccionada = index + (currentPage - 1) * itemsPerPage;
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  accadena(contid: any, nomcont: any) {
    this.editarContribuyente = true;
    setTimeout(() => {
      this.editarContribuyenteEmitter.emit({contid: contid, nomcont: nomcont});
    }, 100);   
  }

  cerrarEdicion(event) {
    this.editarContribuyente = false;
    this.ngOnInit();
  }

}
