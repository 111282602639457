import { Component, OnInit, Input } from '@angular/core';
import swal2 from 'sweetalert2';
import * as fs from 'file-saver';
import { ReportesService } from '../../../services/reportes/reportes.service';
import { StpService } from '../../../services/stp/stp.service';
import { ParametrosGeneralesService } from '../../../services/parametrosgenerales/parametrosgenerales.service';

@Component({
  selector: 'app-conciliacion',
  templateUrl: './conciliacion.component.html',
  styles: []
})
export class ConciliacionComponent implements OnInit {
  cols = [];
  _selectedColumns = [];
  colspdf = [];
  selectedColumnsp = [];
  exportColumns = [];
  facturas = [];
  fileName = '';
  empresa = '';
  datos = [];

  constructor(private stpService: StpService,
              private parametrosService: ParametrosGeneralesService) { }

  ngOnInit() {
    this.cols = [
      // {field: 'idEF', header: ''},
      {field: 'claveRastreo', header: ''},
      // {field: 'conceptoPago', header: ''},
      {field: 'cuentaBeneficiario', header: ''},
      // {field: 'cuentaOrdenante', header: ''},
      {field: 'empresa', header: ''},
      {field: 'estado', header: ''},
      {field: 'fechaOperacion', header: ''},
      {field: 'institucionContraparte', header: ''},
      {field: 'institucionOperante', header: ''},
      // {field: 'medioEntrega', header: ''},
      {field: 'monto', header: ''},
      {field: 'nombreBeneficiario', header: ''},
      // {field: 'nombreOrdenante', header: ''},
      // {field: 'nombreCep', header: ''},
      // {field: 'rfcCep', header: ''},
      // {field: 'sello', header: ''},
      // {field: 'rfcCurpBeneficiario', header: ''},
      {field: 'referenciaNumerica', header: ''},
      // {field: 'rfcCurpOrdenante', header: ''},
      // {field: 'tipoCuentaBeneficiario', header: ''},
      // {field: 'tipoCuentaOrdenante', header: ''},
      {field: 'tipoPago', header: ''},
      {field: 'tsCaptura', header: ''},
      {field: 'tsLiquidacion', header: ''},
      {field: 'causaDevolucion', header: ''},
      {field: 'urlCEP', header: ''},
  
    ];
  
      this._selectedColumns = this.cols;
      this.colspdf = [
      //  { field:  'id_factura', header: 'ID'},
      // { field:  'id_factura', header: 'ID Factura'},
      // { field:  'oper', header: 'Operacion'},
      // { field:  'clave_id', header: 'Clave'},
      // { field:  'cuenta_origen', header: 'Cuenta Origen'},
      // { field:  'cuenta_destino', header: 'Cuenta Destino'},
      // { field:  'importe', header: 'Importe'},
      // { field:  'referencia', header: 'Referencia'},
      // { field:  'descripcion', header: 'Descripcion'},
      // { field:  'rfc_ordenante', header: 'RFC Ordenante'},
      // { field:  'iva', header: 'IVA'},
      // { field:  'fecha_aplicacion', header: 'Fecha Aplicacion'},
      // { field:  'instruccion_pago', header: 'Instruccion Pago'}
      { field:  'institucion_contraparte', header: 'Institucion Contraparte'},
      { field:  'clave_rastreo', header: 'Clave de Rastreo'},
      { field:  'nombre_beneficiario', header: 'Nombre de Beneficiario'},
      { field:  'rfc_curp_beneficiario', header: 'Rfc Curp Beneficiario'},
      { field:  'tipo_pago', header: 'Tipo de Pago'},
      { field:  'tipo_cuenta_beneficiario', header: 'Tipo Cuenta Beneficiario'},
      { field:  'monto', header: 'Monto'},
      { field:  'cuenta_beneficiario', header: 'Cuenta Beneficiario'},
      { field:  'concepto_pago', header: 'Concepto de Pago'},
      { field:  'referencia_numerica', header: 'Referencia Numerica'},
      { field:  'institucion_operante', header: 'Institucion Operante'},
      { field:  'empresa', header: 'Empresa'}
  ];
      this.selectedColumnsp = this.cols;
      this.exportColumns = this.colspdf.map(col => ({title: col.header, dataKey: col.field}));

      this.parametrosService.getGeneralParam('NOMBRE_ORDENANTE').subscribe(resp => {
        this.empresa = resp['value'];
      })
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  generarReporte() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
  });
    swal2.showLoading();
  
    
    const fechaConsulta = ((document.getElementById('fechaconsulta')as HTMLInputElement).value);
    const type = ((document.getElementById('type')as HTMLInputElement).value);
    const page = ((document.getElementById('page')as HTMLInputElement).value);
    const size = ((document.getElementById('size')as HTMLInputElement).value);
  
    let params = {
      empresa: this.empresa,
      page: page,
      tipoOrden: type,
      size: size
    }

    if(fechaConsulta != ''){
      params['fechaOperacion'] = fechaConsulta.replace(/-/g, '');
    }
    
    this.stpService.conciliacion(params).subscribe(resp => {
      console.log('conciliacion', resp);
      try{
      this.datos = resp['response']['datos'];
      }catch{
        swal2.fire('Atención', 'Hubo un problema al generar el reporte', 'info');
      }
      swal2.close();
    }, err => {
      console.log('error', err);
      swal2.close();
    })

  
  
  }
  
  
    exportexcel() {
  
      /*
      console.log(this.facturas);
       const element = document.getElementById('tablaFacturas');
       const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       if ( this.facturas.length > 0 ) {
        this.fileName = this.facturas[0].payment_report_folio + '.xlsx';
       }
       XLSX.writeFile(wb, this.fileName);  */
       //console.log(this.facturas)
       //let numberrow = 2;
       this.fileName = this.datos[0].claveRastreo + '.xlsx';
       const Excel = require('exceljs');
       let workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet('stp');
      let header=[
        'claveRastreo',
        'cuentaBeneficiario',
        'empresa',
        'estado',
        'fechaOperacion',
        'institucionContraparte',
        'institucionOperante',
        'monto',
        'nombreBeneficiario',
        'referenciaNumerica',
        'tipoPago',
        'tsCaptura',
        'tsLiquidacion',
        'causaDevolucion',
        'urlCEP',
      ];
      worksheet.addRow(header);
      for (const prop in this.datos) {
        /*let fechaaplicacion = this.facturas[prop].fecha_aplicacion;
        console.log(fechaaplicacion)
        let fecapli = fechaaplicacion.split('/');
        console.log(fecapli) */
        worksheet.addRow([
          this.datos[prop].claveRastreo,
          this.datos[prop].cuentaBeneficiario,
          this.datos[prop].empresa,
          this.datos[prop].estado,
          this.datos[prop].fechaOperacion,
          this.datos[prop].institucionContraparte,
          this.datos[prop].institucionOperante,
          this.datos[prop].monto,
          this.datos[prop].nombreBeneficiario,
          this.datos[prop].referenciaNumerica,
          this.datos[prop].tipoPago,
          this.datos[prop].tsCaptura,
          this.datos[prop].tsLiquidacion,
          this.datos[prop].causaDevolucion,
          this.datos[prop].urlCEP,
        ]);
          
          //worksheet.getCell('K' + numberrow).value = new Date(`${fecapli[2]}, ${fecapli[1]}, ${fecapli[0]} GMT-0000`);
          //numberrow = numberrow + 1;
      } 
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: '.xlsx' });
        const file = blob;
        fs.saveAs(blob, this.fileName);
      });
    }

}
