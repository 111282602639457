import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import swal2 from 'sweetalert2';
import { FacturasService, AltaSolicitudesService } from "src/app/services/service.index";
import { FormControl, FormGroup } from "@angular/forms";
@Component({
  selector: 'app-altafactlayout',
  templateUrl: './altafactlayout.component.html',
  styleUrls: ['./altafactlayout.component.css']
})
export class AltafactlayoutComponent implements OnInit {
  arrayBuffer:any;
  facturasexc: any[] = [];
  file:File;
  nombreFile: string;
  idu: string;
  filespdf = [];
  filesNamespdf = [];
  namesFacExc = [];
  rfccompany: string;
  procesadasbien = 0;
  procesadasmal = 0;
  resulgood = [];
  resulbad = [];
  cuantosPdf = 0;
  muestrareporte: boolean;
  tienerfc: boolean;
  cols: any[];
  facturasusuario: any[];
  estatusfacturas: any[] = [];
  estatusfacturas2: any[];
  factoraje_type = 'SIN_RECURSO';
  myForm = new FormGroup({
    filesPdf: new FormControl(),
    filesXml: new FormControl(),
  });
  constructor(private facturas: FacturasService,
    private _solicitudesservice: AltaSolicitudesService
  ) { }

  ngOnInit() {
    
    this.tienerfc = true;
    this.muestrareporte = false
    this.resulgood = [];
    this.resulbad = [];
    this.procesadasbien = 0;
    this.procesadasmal = 0;
    this.nombreFile = 'Ninguno'
    this.cuantosPdf = 0;
    this.idu = localStorage.getItem('id');
    this._solicitudesservice.getGeneralParam('FACTORAJE_TYPE').subscribe(r => {
      if(r['value'] == 'SIN_RECURSO' || r['value'] == 'CON_RECURSO'){
        this.factoraje_type = r['value'];
      }
    })
    this.facturas.getRfcUser(this.idu).subscribe(r => {
    if (r.length === 0) {
      swal2.fire('Este usuario no tiene un RFC de empresa asignado','Contacte al administrador del sistema','info');
      this.tienerfc = false;
    } else {
      this.rfccompany = r[0].rfc_contribuyente;
    }
    })
    this.facturas.getFacturasUser(this.idu).subscribe(r => {
    this.facturasusuario = r
    });
    this.facturas.getEstatusFacturas().subscribe(async r => {
    for (let sta in r) {
      this.estatusfacturas[sta] = {label: r[sta], value: r[sta]}
    }
    })
    this.cols = [
      { field: 'id_factura', header: 'ID' },
      { field: 'folio_factura', header: 'Folio factura' },
      { field: 'folio_solicitud', header: 'Folio solicitud' },
      { field: 'uuid', header: 'UUID' },
      { field: 'moneda', header: 'Moneda' },
      { field: 'fecha_vencimiento', header: 'Fecha de vencimiento' },
      { field: 'estatus', header: 'Estatus' },
      { field: 'total_factura', header: 'Total factura' },
      { field: 'monto_operado', header: 'Monto operado' }
  ];
  this.estatusfacturas2 = [
    { label: 'PENDIENTE', value: 'PENDIENTE' },
    { label: 'EJECUTADA', value: 'EJECUTADA' },
    { label: 'LIQUIDADA', value: 'LIQUIDADA' },
    { label: 'REVISION', value: 'REVISION' }
];
  }

  reset() {
    window.location.reload();
  }

  dragEnter(id: string) {
    document.getElementById(id).style.border = "dashed 2px blue";
  }
  dragLeave(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }
  drop(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }

  async onFileChange(event) {
    this.file= event.target.files[0];
    //console.log(this.file.name)
    this.nombreFile = this.file.name;
    //console.log(this.file)
  }

  async onFileChangePdf(event) {
    for await (let num of event) {
      if (num.type.toLowerCase().includes("pdf")) {
        if (this.filespdf.length === 0) {
          this.filespdf.push(num);
          this.filesNamespdf.push(num.name.substring(0, num.name.length - 4));
        } else {
          if (!this.filesNamespdf.includes(num.name)) {
            this.filespdf.push(num);
            this.filesNamespdf.push(num.name.substring(0, num.name.length - 4));
          }
        }
        this.myForm.patchValue({
          filesPdf: this.filespdf,
        });
        this.filesNamespdf.sort();
      }
    }
    this.cuantosPdf = this.myForm.value.filesPdf.length;
  }

  async procesarFacturas() {
    swal2.fire("Procesando", "Espere por favor", "info");
    swal2.showLoading();
    this.procesadasbien = 0;
    this.procesadasmal = 0;
    
    if (this.file === undefined) {
      swal2.fire('Debe seleccionar al menos un archivo xls o xlsx', '', 'info');
      return;
    }
    if (this.myForm.value.filesPdf === null) {
      swal2.fire('Debe seleccionar al menos un archivo PDF', '', 'info');
      return;
    }
  
    let fileReader = new FileReader();
    fileReader.onload = async (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary", cellDates: true });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.facturasexc = XLSX.utils.sheet_to_json(worksheet, { header: ['Invoice', 'Bill', 'CRN', 'Total', 'InvoiceDate', 'Currency'], blankrows: false });
      let f = new Date();
      let fechaactual = this.formatoFechaexcel(f.toString());
      this.facturasexc.pop();
  
      if (this.myForm.value.filesPdf.length !== this.facturasexc.length) {
        swal2.fire('El número de archivos PDF debe coincidir con el número de facturas en el xls a procesar', '', 'info');
        return;
      }
  
      for (let fac of this.facturasexc) {
        this.namesFacExc.push(fac.Invoice);
      }
      this.namesFacExc.sort();
      this.filesNamespdf.sort();
      let isEqual = JSON.stringify(this.filesNamespdf) === JSON.stringify(this.namesFacExc);
      if (!isEqual) {
        swal2.fire("Los nombres de los PDF y xls deben coincidir", "", "info");
        return;
      }
  
      for (let fac of this.facturasexc) {
        try {
          let data = {
            token: "",
            secret_key: "",
            invoice_folio: fac.Invoice,
            invoice_date: this.formatoFechaexcel(fac.InvoiceDate),
            currency: fac.Currency,
            total: fac.Total,
            xml: "https://xml2",
            uuid: this.rfccompany + fac.Invoice,
            invoice_serie: f.getFullYear(),
            document_type: 'INVOICE',
            pdf: "",
            entry_date: fechaactual,
            status: "REVISION",
          };

          if(this.factoraje_type == 'SIN_RECURSO'){
            data['emitter_rfc'] = fac.CRN
            data['receiver_rfc'] = this.rfccompany
          }else if(this.factoraje_type == 'CON_RECURSO'){
            data['emitter_rfc'] = this.rfccompany
            data['receiver_rfc'] = fac.CRN
          }
  
          for (let pdf of this.myForm.value.filesPdf) {
            if (pdf.name.substring(0, pdf.name.length - 4) === fac.Invoice) {
              let respPdf = await this.retry(() => this.callupload(pdf), 3);
              if (!respPdf.status) {
                this.procesadasmal++;
                this.resulbad.push({ status: false, msg: 'Error al subir archivo PDF', nombre: pdf.name.substring(0, pdf.name.length - 4) });
                continue;
              }
  
              data.pdf = respPdf.resp.Location;
              let response = await this.retry(() => this.subefactura(data, pdf.name.substring(0, pdf.name.length - 4)), 3);
              if (response.msg === "OK") {
                this.procesadasbien++;
                this.resulgood.push(response);
                if (response.resp.error.status === 500) {
                  this.procesadasmal++;
                } else if (response.resp.error.errors[0].includes('Ya existe una factura dada de alta con el uuid')) {
                  this.procesadasmal++;
                  this.resulbad.push(response);
                } else {
                  this.procesadasmal++;
                  this.facturas.delteFile(respPdf.resp.key);
                  this.resulbad.push(response);
                }
              }
            }
          }
        } catch (error) {
          console.log(error);
          /* this.procesadasmal++;
          this.resulbad.push({ status: false, msg: 'Error en procesamiento', nombre: fac.Invoice }); */
        }
      }
  
      this.muestrareporte = true;
      swal2.close();
    };
    fileReader.readAsArrayBuffer(this.file);
  }
  
  async retry(fn, retries) {
    for (let i = 0; i < retries; i++) {
      try {
        return await fn();
      } catch (error) {
        if (i === retries - 1) throw error;
        await this.delay(1000); // Espera 1 segundo antes de reintentar
      }
    }
  }
  
  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  formatoFechaexcel(fecha: string) {
    //  fecha = '06/07/2021';
    const a = new Date(fecha);
    // console.log(a);
    a.setMinutes(a.getMinutes() + a.getTimezoneOffset());
    let montha = "" + (a.getMonth() + 1);
    let daya = "" + a.getDate();
    const yeara = a.getFullYear();

    if (montha.length < 2) {
      montha = "0" + montha;
    }
    if (daya.length < 2) {
      daya = "0" + daya;
    }

    const resul = [yeara, montha, daya].join("-");

    return resul;
  }

  async subefactura(data, name) {
    try {
      const r = await (await this.facturas.guardaFacturaLayout(data)).toPromise();
      let resp = {
        status: true,
        resp: r,
        msg: "OK",
        nombre: name,
      };
      return resp;
    } catch (error) {
      let resp = {
        status: false,
        resp: error,
        msg: "Error",
        nombre: name,
      };
      return resp;
    }
  }

  async callupload(file) {
    try { 
      const r = await this.facturas.uploadFileLayout(file);
      let resp = {
        status: true,
        resp: r,
        msg: "Archivo subido correctamente",
      };
      return resp;
    } catch (error) {
      let resp = {
        status: false,
        resp: error,
        msg: "Fallo al subir archivo",
      };
      return resp;
    }
  }
  exportexcelcorrectaslayout() {
    /* table id is passed over here */
    const element = document.getElementById("facturasCorrectas");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* save to file */
    XLSX.writeFile(wb, "facturascorrectas.xlsx");
  }

  exportexcelerroreslayout() {
    /* table id is passed over here */
    const element = document.getElementById("facturasErrores");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* save to file */
    XLSX.writeFile(wb, "facturaserrores.xlsx");
  }
}
