import { Component, OnInit, Input } from '@angular/core';
import { ReportesService } from '../../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import { Angular2Txt } from 'angular2-txt/Angular2-txt';
//import * as XLSX from 'xlsx';
//import jsPDF from 'jspdf';
//import 'jspdf-autotable';
import * as fs from 'file-saver';
//declare var $;


@Component({
  selector: 'app-monex',
  templateUrl: './monex.component.html',
  styles: []
})
export class MonexComponent implements OnInit {

  constructor(public _reportesservice: ReportesService,
    public http: HttpClient) { }

  token = localStorage.getItem('token');
  //doc = new jsPDF();
  facturas: any[] = [];
  usuario: string;
  cols: any[];
  colspdf: any[];
  selectedFac: any[];
  router: Router;
  fileName = 'ListaDeFacturas.xlsx';
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];

  _selectedColumns: any[];

  ngOnInit() {

    this.cols = [

      //  { field:  'id_factura', header: 'ID'},
      { field: 'payment_report_folio', header: 'Folio' },
      { field: 'nombre', header: 'Nombre' },
      { field: 'cuenta_clabe', header: 'Cuenta clave' },
      { field: 'cantidad_pagar', header: 'Cantidad a pagar' },
      { field: 'correo', header: 'Correo' },
      { field: 'concepto_pago', header: 'Concepto pago' },
      { field: 'referencia_numerica', header: 'Referencia' }
    ];

    this._selectedColumns = this.cols;
    this.colspdf = [

      //  { field:  'id_factura', header: 'ID'},
      { field: 'id_factura', header: 'ID Factura' },
      { field: 'oper', header: 'Operacion' },
      { field: 'clave_id', header: 'Clave' },
      { field: 'cuenta_origen', header: 'Cuenta Origen' },
      { field: 'cuenta_destino', header: 'Cuenta Destino' },
      { field: 'importe', header: 'Importe' },
      { field: 'referencia', header: 'Referencia' },
      { field: 'descripcion', header: 'Descripcion' },
      { field: 'rfc_ordenante', header: 'RFC Ordenante' },
      { field: 'iva', header: 'IVA' },
      { field: 'fecha_aplicacion', header: 'Fecha Aplicacion' },
      { field: 'instruccion_pago', header: 'Instruccion Pago' }
    ];
    this.selectedColumnsp = this.cols;
    this.exportColumns = this.colspdf.map(col => ({ title: col.header, dataKey: col.field }));

  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  generarReporte() {

    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();

    const d = new Date((document.getElementById('fechaconsulta') as HTMLInputElement).value);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    const fecharepor = [year, month, day].join('-');
    // const type = ((document.getElementById('type') as HTMLInputElement).value);

    this._reportesservice.getMonex(fecharepor).subscribe(resp => {
      console.log(resp)
      this.facturas = resp;
      swal2.close();
      if (this.facturas.length === 0) {

        swal2.fire(
          'No se encontraron datos con la fecha:',
          fecharepor,
          'error'
        );
      } else {
        // tslint:disable-next-line: forin
        /*   for (const prop in this.facturas) {
             this.facturas[prop].cantidad_pagar = this.facturas[prop].cantidad_pagar.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
           } */
      }
    }, (err) => {
      swal2.close();
      console.log(err);
      swal2.fire(
        'Error al Consultar los Datos',
        '',
        'error'
      );
      this.ngOnInit();
    });


  }


  exportexcel() {
    this.fileName = this.facturas[0].payment_report_folio + '.xlsx';
    const Excel = require('exceljs');
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('banorte');
    let header = ['Folio', 'Nombre', 'Cuenta clave', 'Cantidad a pagar', 'Correo', 'Concepto pago', 'Referencia'];
    worksheet.addRow(header);
    for (const prop in this.facturas) {
      worksheet.addRow([this.facturas[prop].payment_report_folio, this.facturas[prop].nombre, this.facturas[prop].cuenta_clabe, this.facturas[prop].cantidad_pagar,
      (this.facturas[prop].correo), this.facturas[prop].concepto_pago, this.facturas[prop].referencia_numerica,]);
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: '.xlsx' });
      const file = blob;
      fs.saveAs(blob, this.fileName);
    });
  }

  exporttxt() {
    var options = {
      fieldSeparator: '',
      quoteStrings: ''
    };
    let datos = [];
    this.fileName = this.facturas[0].payment_report_folio;
    for (const prop in this.facturas) {
      //  this.facturas[prop].importe = this.facturas[prop].importe.replaceAll(',', '')
      datos.push({
        to: this.facturas[prop].payment_report_folio,
        tq: this.facturas[prop].nombre,
        tw: this.facturas[prop].cuenta_clabe,
        te: this.facturas[prop].cantidad_pagar,
        tr: this.facturas[prop].correo,
        tt: this.facturas[prop].concepto_pago,
        ty: this.facturas[prop].referencia_numerica,
      })
    }
    new Angular2Txt(datos, this.fileName, options);

  }


  /*exportpdf() {

   import('jspdf').then( jsPDF => {
    import('jspdf-autotable').then(x => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(this.exportColumns, this.facturas);
        doc.save('ListaFacturas.pdf');
    });
});

  } */

}
